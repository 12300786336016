import { useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const Input = ({ name, label, ...others }) => {
  const { register } = useFormContext();
  const { errors, isDirty } = useFormState();
  const myErrors = errors[name];
  const myIsDirty = isDirty[name];
  return useMemo(
    () => (
      <TextField
        {...register(name)}
        name={name}
        label={label}
        error={!!myErrors}
        helperText={myErrors?.message ?? ''}
        type="text"
        size="small"
        margin="dense"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        {...others}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myErrors, myIsDirty]
  );
};

export default Input;
