import { useParams, Routes, Route, Navigate } from 'react-router-dom';

import { useProjectByUid } from 'store/swr/useProjects';
import { matchProjectAuth, AUTH_EDITOR } from 'utils/matchAuth';
import Error, { STATUS_NOT_AUTHORIZED } from 'pages/Error';
import Authorized from 'components/Authorized';
import ProjectLayout from './ProjectLayout';
import Overview from './Overview';
import Tasks from './Tasks';
import Documents from './Documents';
import Publications from './Publications';

const pages = [
  {
    label: 'Project Overview',
    link: 'overview',
  },
  {
    label: 'Task Management',
    link: 'tasks',
  },
  {
    label: 'Related Documents',
    link: 'documents',
    auth: AUTH_EDITOR,
  },
  // {
  //   label: 'Publications',
  //   link: 'publications',
  // },
];

const Project = () => {
  const { uid } = useParams();
  const { data: { auth = null } = {} } = useProjectByUid(uid);
  // console.log(project);
  const authedPages = pages.filter((page) => matchProjectAuth(page.auth, auth));
  return (
    <Routes>
      <Route
        path="/*"
        element={<ProjectLayout uid={uid} pages={authedPages} />}
      >
        <Route index element={<Navigate replace to="overview" />} />
        <Route path="overview/*" element={<Overview />} />
        <Route path="tasks/*" element={<Tasks />} />

        <Route
          path="documents/*"
          element={
            <Authorized
              authority={AUTH_EDITOR}
              userAuth={auth}
              noMatch={<Error status={STATUS_NOT_AUTHORIZED} />}
            >
              <Documents />
            </Authorized>
          }
        />
        {/* <Route path="publications/*" element={<Publications uid={uid} />} /> */}
      </Route>
    </Routes>
  );
};

export default Project;
