import request from './request';
import { hash } from 'utils/encrypt';

// Project API //
export const updateProject = async (uid, project) => {
  const url = `/api/projects/${uid}`;
  const resData = await request(url, 'PUT', { project });
  return resData.project;
};

export const createProject = async (uid, project) => {
  const url = `/api/projects/${uid}`;
  const resData = await request(url, 'POST', { project });
  return resData.project;
};

// Tasks API //
export const updateProjectTasks = async (uid, tasks, tasksRemoved) => {
  // TODO: this should be separated to POST, PUT, DELETE to be more restful?
  const url = `/api/projects/${uid}/tasks`;
  const resData = await request(url, 'POST', { tasks, removed: tasksRemoved });
  return resData.tasks;
};

export const updateTaskProgress = async (taskId, progressData) => {
  const url = `/api/tasks/${taskId}/progress-form`;
  const resData = await request(url, 'POST', progressData);
  return resData;
};

export const updateTaskStatus = async (taskId, status) => {
  const url = `/api/tasks/${taskId}`;
  const resData = await request(url, 'PATCH', { status });
  return resData;
};

// Related Documents API //
export const createDocument = async (projectUid, document) => {
  const url = `/api/projects/${projectUid}/documents`;
  const resData = await request(url, 'POST', { document });
  return resData.document;
};

export const updateDocument = async (docuemntId, document) => {
  const url = `/api/documents/${docuemntId}`;
  const resData = await request(url, 'PUT', { document });
  return resData.document;
};

export const deleteDocument = async (docuemntId) => {
  const url = `/api/documents/${docuemntId}`;
  const resData = await request(url, 'DELETE', {});
  return resData;
};

// Auth API //
export const createUser = async (user) => {
  const url = `/api/users/new`;
  if (user.password) {
    user.password = hash(user.password);
  }
  const resData = await request(url, 'POST', { user });
  return resData;
};

export const updateUser = async (userId, user) => {
  const url = `/api/users/${userId}`;
  if (user.password) {
    user.password = hash(user.password);
  }
  const resData = await request(url, 'PUT', { user });
  return resData;
};

export const deleteUser = async (userId) => {
  const url = `/api/users/${userId}`;
  const resData = await request(url, 'DELETE', {});
  return resData;
};

// match user and person
export const matchUser = async (userId, personId) => {
  const url = `/api/people/${personId}`;
  const resData = await request(url, 'PATCH', { userId });
  return resData;
};

// unmatch user and person
export const unmatchUser = async (personId) => {
  const url = `/api/people/${personId}`;
  const resData = await request(url, 'DELETE');
  return resData;
};

// project - user access
export const addProjectAccess = async (uid, userId) => {
  const url = `/api/projects/${uid}/access`;
  const resData = await request(url, 'PATCH', { userId, auth: 'editor' });
  return resData;
};

export const deleteProjectAccess = async (uid, userId) => {
  const url = `/api/projects/${uid}/access`;
  const resData = await request(url, 'DELETE', { userId });
  return resData;
};
