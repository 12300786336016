import { DateTime } from 'luxon';

export const percentFormatter = (val) => {
  if (!val) {
    return '—';
  }
  return `${val} %`;
};

export const dateFormatter = (val, format = 'yyyy-MM-dd') => {
  try {
    return DateTime.fromISO(val).toFormat(format);
  } catch (err) {
    return '—';
  }
};
