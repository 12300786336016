// import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ControlledAutocomplete = ({
  label,
  name,
  options,
  isOptionEqualToValue = (option, value) => option._id === value._id,
  getOptionLabel = (option) => option.name,
  ...rest
}) => {
  const { control } = useFormContext();
  // const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // console.log(value, options);
        return (
          <Autocomplete
            value={value || null}
            onChange={(_, data) => onChange(data)}
            // inputValue={inputValue}
            // onInputChange={(_, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            options={options}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            label={label}
            size="small"
            margin="dense"
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot':
                {
                  paddingRight: '6px',
                },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                fullWidth
                error={!!error}
                helperText={error?.message ?? ''}
                {...rest}
              />
            )}
          />
        );
      }}
      onChange={([, data]) => data}
      name={name}
      control={control}
    />
  );
};

ControlledAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
};

export default ControlledAutocomplete;
