import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

import PersonMatchForm from 'forms/PersonMatchForm';

const AssignButton = ({ user, person, mutate }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterSubmit = () => {
    mutate();
    handleClose();
  };

  return (
    <>
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{person ? person.name : 'Not Assigned'}</span>
        <IconButton
          color="primary"
          aria-label="assign button"
          component="span"
          size="small"
          sx={{ padding: 0, height: '1.4rem' }}
          onClick={handleClickOpen}
        >
          {person ? (
            <EditIcon fontSize="small" />
          ) : (
            <AddIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-user-person-connection"
        aria-describedby="edit-user-person-connection"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Match Person</DialogTitle>
        <DialogContent>
          <PersonMatchForm
            user={user}
            person={person}
            afterSubmit={afterSubmit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AssignButton.propTypes = {
  user: PropTypes.object.isRequired,
  person: PropTypes.object,
  mutate: PropTypes.func.isRequired,
};

export default AssignButton;
