import { useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';

import ProjectTasksForm from 'forms/ProjectTasksForm';

const Tasks = () => {
  const { uid } = useParams();
  const { setActiveProject, setIsFormtDirty } = useOutletContext();

  useEffect(() => {
    setActiveProject({ uid });
  }, [uid, setActiveProject]);

  const afterSubmitTasks = () => {
    setIsFormtDirty(false);
  };

  return (
    <ProjectTasksForm
      uid={uid}
      afterSubmit={afterSubmitTasks}
      setDirty={setIsFormtDirty}
    />
  );
};

export default Tasks;
