// import { useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import { useProjects } from 'store/swr/useProjects';
import AsyncAutoComplete from 'components/AsyncAutoComplete';
import AddButton from 'components/AddButton';
import DeleteButton from './components/DeleteButton';

const ProjectSelect = () => {
  const { data: { projects = [] } = {}, mutate } = useProjects();
  const { activeProject, setActiveProject } = useOutletContext();
  const navigate = useNavigate();

  const afterSubmitProject = () => {
    setActiveProject(undefined);
    mutate();
  };

  const toEdit = (activeProject) => {
    navigate(`${activeProject ? activeProject.uid : 'new'}/overview`);
  };

  const getActiveProjectOption = (activeProject) => {
    if (!activeProject?.uid) {
      return null;
    }
    if (!projects) {
      return activeProject;
    }
    return (
      projects.find((item) => item.uid === activeProject.uid) || activeProject
    );
  };

  return (
    <div>
      <Box
        sx={{
          marginBottom: '2.4rem',
        }}
      >
        <AddButton onClick={() => toEdit(null)} label="Add New Project" />
      </Box>

      <Box sx={{ display: 'flex', gap: '2rem', marginBottom: '2.4rem' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AsyncAutoComplete
            value={getActiveProjectOption(activeProject)}
            setValue={setActiveProject}
          />
        </Box>
        <DeleteButton
          project={activeProject}
          afterSubmit={afterSubmitProject}
        />
        <Button
          variant="contained"
          sx={{ width: '10rem', fontSize: '1.6rem' }}
          startIcon={<EditIcon />}
          disabled={!activeProject}
          onClick={() => toEdit(activeProject)}
        >
          Edit
        </Button>
      </Box>

      <Box sx={{ marginBottom: '30rem' }} />
    </div>
  );
};

export default ProjectSelect;
