import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTaskForm } from 'store/swr/useTasks';
import { updateTaskProgress } from 'services/apis';

import Grid from '@mui/material/Grid';
import {
  Form,
  Input,
  Radio,
  Percent,
  Upload,
  SubmitButton,
} from 'components/Form';
import GridCard from 'components/Form/GridCard';
import Loading from 'components/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// validation
const schema = yup
  .object({
    needExtension: yup.boolean().required(),
    deliverables: yup.string().required(),
    percentQuarter: yup
      .number()
      .typeError('Please enter a number')
      .min(0, 'Percentage must be greater than or equal to 0')
      .max(100, 'Percentage must be less than or equal to 100')
      .required('Please enter a number'),
    percentProject: yup
      .number()
      .typeError('Please enter a number')
      .min(0, 'Percentage must be greater than or equal to 0')
      .max(100, 'Percentage must be less than or equal to 100')
      .required('Please enter a number'),
    // qrFile: yup
    //   .mixed()
    //   .required('File is required')
    //   .test('file-size', 'PDF only', (fileList) => {
    //     for (const file of fileList) {
    //       if (!['application/pdf'].includes(file.type)) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   }),
  })
  .required();

const QRActionForm = ({ taskId, afterSubmit }) => {
  const { data, loading, error, mutate } = useTaskForm(taskId);

  const onSubmit = async (data) => {
    try {
      await updateTaskProgress(taskId, data);
      mutate();
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: data,
          resolver: yupResolver(schema),
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <GridCard label="Project Progress">
            <Grid item xs={12}>
              <Radio
                name="needExtension"
                label="Is a new no-cost extension needed?"
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="deliverables"
                label="List any deliverables (i.e. tools, products, datasets, etc.) produced this quarter"
                required
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
              <Percent
                name="percentQuarter"
                label="Percentage of Project Completed this Quarter"
                required
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
              <Percent
                name="percentProject"
                label="Total Percentage of Project Complete"
                required
              />
            </Grid>
          </GridCard>
          <Grid item xs={12}>
            <Upload
              name="qrFiles"
              label="Quarterly Report Document"
              action={`/api/tasks/${taskId}/upload`}
            />
          </Grid>
          <Grid item xs={12}>
            <Upload
              name="qrOtherfiles"
              label="All other supporting documents/media"
              action={`/api/tasks/${taskId}/upload`}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

QRActionForm.propTypes = {
  taskId: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
};

export default QRActionForm;
