import PropTypes from 'prop-types';
import LoadingIcon from '../LoadingIcon';

const getErrorMessage = (err) => {
  if (typeof err === 'string') {
    return err;
  } else if (typeof err === 'object') {
    return String(err?.message);
  }
  return String(err);
};
const Loading = ({ loading, error, children }) => {
  return error ? (
    <div>{getErrorMessage(error)}</div>
  ) : loading ? (
    <LoadingIcon />
  ) : (
    <>{children}</>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Loading;
