import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function BooleanCheckBox({ name, label, ...rest }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        label ? (
          <FormControlLabel
            {...field}
            {...rest}
            control={<Checkbox />}
            label={label}
          />
        ) : (
          <Checkbox
            onChange={(e) => field.onChange(e.target.checked)}
            checked={field.value}
            {...rest}
          />
        )
      }
    />
  );
}

BooleanCheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
