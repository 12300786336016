import { useState } from 'react';
import toast from 'react-hot-toast';
import { GridActionsCellItem, DataGrid } from '@mui/x-data-grid';
// import { randomInt, randomUserName } from '@mui/x-data-grid-generator';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useUsers, usePeople } from 'store/swr/usePeople';
import { deleteUser, matchUser, unmatchUser } from 'services/apis';
import Loading from 'components/Loading';
import UserForm from 'forms/UserForm';
import AddButton from 'components/AddButton';
import AssignButton from './AssignButton';

const getColumns = (editCallback, deleteCallback, mutate) => [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'email', headerName: 'Email', width: 240 },
  { field: 'role', headerName: 'User Type', width: 120, align: 'center' },
  {
    field: 'check',
    headerName: 'Is PI/Co-PI',
    width: 100,
    renderCell: (params) => {
      return (
        <input
          type="checkbox"
          checked={params.row.person ? true : false}
          onChange={async (e) => {
            let row = params.row;
            if (e.currentTarget.checked) {
              if (!row.expectedPersonId) {
                toast.error(
                  `No person with the same email as ${row.name}, please match person manually or check Monday's contacts list`
                );
              } else {
                await matchUser(row.id, row.expectedPersonId);
                toast.success(`Successfully set ${row.name} as PI/Co-PI`);
              }
            } else {
              await unmatchUser(row.person._id);
              toast.success(`Successfully unset ${row.name} as PI/Co-PI`);
            }
            mutate();
          }}
        />
      );
    },
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 200,
    renderCell: (params) => {
      return (
        <AssignButton
          user={params.row}
          person={params.row.person}
          mutate={mutate}
        />
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Edit',
    type: 'actions',
    getActions: (params) => [
      <GridActionsCellItem
        key="edit"
        label="Edit"
        icon={<EditIcon />}
        onClick={() => {
          document.body.click();
          editCallback(params);
        }}
      />,
      <GridActionsCellItem
        key="delete"
        label="Delete"
        icon={<DeleteIcon />}
        onClick={() => {
          document.body.click();
          deleteCallback(params);
        }}
      />,
    ],
  },
];

export default function UpdateRowsProp() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(false);

  const { data: { users = [] } = {}, loading, error, mutate } = useUsers();

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setActiveRow(null);
  };

  const handleEdit = (params) => {
    setActiveRow(params.row);
    handleOpen();
  };

  const handleDeleteConfirm = (params) => {
    setActiveRow(params.row);
    setIsConfirmOpen(true);
  };

  const handleDelete = async () => {
    const userId = activeRow._id;
    try {
      await deleteUser(userId);
      mutate();
      setActiveRow(null);
      setIsConfirmOpen(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const afterSubmit = () => {
    mutate(); // update tasks data
    setIsModalOpen(false);
    setActiveRow(null);
  };

  const { data: { people = [] } = {} } = usePeople();
  const personEmailToPersonId = new Map();
  for (const person of people) {
    personEmailToPersonId[person.email] = person._id;
  }

  const columns = getColumns(handleEdit, handleDeleteConfirm, mutate);
  const rows = users?.map((user) => ({
    ...user,
    id: user._id,
    expectedPersonId: personEmailToPersonId[user.email],
  }));

  return (
    <Loading loading={loading} error={error}>
      <div style={{ width: '100%', marginBottom: '8rem' }}>
        <Box
          sx={{
            marginBottom: '2.4rem',
          }}
        >
          <AddButton onClick={handleOpen} label="Add User" />
        </Box>
        <Box sx={{ height: 700, bgcolor: 'background.paper' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50]}
            disableColumnMenu
            density="compact"
          />
        </Box>
        <Dialog
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="edit-user-form-modal"
          aria-describedby="edit-user-form-modal"
        >
          <DialogTitle>{activeRow ? 'Edit User' : 'Add User'}</DialogTitle>
          <DialogContent sx={{ width: '40rem' }}>
            <UserForm userId={activeRow?._id} afterSubmit={afterSubmit} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false);
          }}
        >
          <DialogTitle>Are you sure to delte this user?</DialogTitle>
          <DialogContent>
            <DialogContentText>{activeRow?.name}</DialogContentText>
            <DialogContentText>{activeRow?.email}</DialogContentText>
            <DialogContentText>{activeRow?.role}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsConfirmOpen(false);
              }}
              autoFocus
            >
              Cancel
            </Button>
            <Button color="error" onClick={handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Loading>
  );
}
