import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';

const DrawerStlyed = ({ open, toggleDrawer, header, children }) => {
  // const [open, setOpen] = useState(false);
  return (
    <Drawer
      open={open}
      onClose={() => {
        toggleDrawer(false);
      }}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': { width: '90%' },
        '& .drawer__header': {
          textAlign: 'center',
          padding: '8px',
          borderBottom: 'thin solid var(--color-text-primary)',
        },
        '& .drawer__container': {
          padding: '3.6rem',
        },
      }}
    >
      {header && <h4 className="drawer__header">{header}</h4>}
      <div className="drawer__container">{children}</div>
    </Drawer>
  );
};

DrawerStlyed.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default DrawerStlyed;
