import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';

import { useStaticDocuments } from 'store/swr/useTasks';
import Loading from 'components/Loading';

export default function DownloadPopup({ label, ...rest }) {
  const [open, setOpen] = useState(false);
  const { data: { files = [] } = {}, loading, error } = useStaticDocuments();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        sx={{ fontSize: '1.6rem', height: '100%' }}
        startIcon={<DownloadIcon />}
        onClick={handleClickOpen}
        {...rest}
      >
        {label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="project-deletion-dialog-title"
        aria-describedby="project-deletio-dialog-description"
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Loading loading={loading} error={error}>
            <Box
              component="ul"
              sx={{
                listStyle: 'none',
                '& li': {
                  margin: '1.6rem',
                },
                '& a': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.8rem',
                  color: 'var(--color-theme-primary)',
                  textDecoration: 'none',
                },
                '& a:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {files.map((item) => (
                <li key={item.path}>
                  <a href={item.path} download>
                    <DownloadIcon />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
            </Box>
          </Loading>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DownloadPopup.propTypes = {
  label: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  // content: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  //   PropTypes.func,
  //   PropTypes.string,
  // ]),
  // handleDelete: PropTypes.func.isRequired,
};
