import axios from 'axios';

const fetcher = async (url, method = 'GET', data = undefined) => {
  console.log(url, method, data);
  let res;
  try {
    res = await axios({ url, method, data });
  } catch (err) {
    if (err.response) {
      const message = err.response.errors?.message || err.message;
      const returnErr = new Error(message);
      returnErr.status = err.response.status;
      throw returnErr;
    }
    throw err;
  }
  console.log(res.data);
  return res.data;
};

export default fetcher;
