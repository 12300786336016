import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useProjects } from 'store/swr/useProjects';

export default function AsyncAutoComplete({ value, setValue }) {
  // const [value, setValue] = useState(null);
  // const [options, setOptions] = useState([]);
  // const loading = open && options.length === 0;
  const { data: { projects = [] } = {}, loading } = useProjects();

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  // console.log(value);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option._id === value._id || option.uid === value.uid
      }
      getOptionLabel={(option) => option.title}
      options={projects}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a Project"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

AsyncAutoComplete.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};
