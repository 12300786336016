import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import styles from './fileLinks.module.scss';

// const fields = ['qrFiles', 'qrOtherfiles', 'files', 'dataLinks'];

const getFiles = (row) => {
  if (!row?.files) {
    return [];
  }
  const fileWrapper = row.files;
  let files = [];
  Object.keys(fileWrapper).forEach((field) => {
    files = [...files, ...fileWrapper[field]];
  });
  return files;
};

const FileLinks = ({ row }) => {
  const files = getFiles(row.uploads);
  return (
    <ul className={styles.filelist}>
      {files.map((item) => (
        <li key={item._id} className={styles['filelist__item']}>
          <a className={styles['filelist__link']} href={item.path} download>
            <DownloadIcon />
            <span>{item.name}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

FileLinks.propTypes = {
  row: PropTypes.object.isRequired,
};

export default FileLinks;
