import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const RadioInput = ({ name, label, options, ...rest }) => {
  const { control } = useFormContext();
  // const { errors, isDirty } = useFormState();
  // const myErrors = errors[name];
  // const myIsDirty = isDirty[name];
  return (
    <>
      <FormLabel component="legend" required={rest?.required}>
        {label}
      </FormLabel>
      {/* <RadioGroup row aria-label={label} name={name} {...register(name)}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio {...register(name)} />}
              label={option.label}
            />
          ))}
        </RadioGroup> */}
      <Controller
        render={({ field }) => (
          <RadioGroup row aria-label={label} {...field}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
        name={name}
        control={control}
      />
    </>
  );
};

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RadioInput;
