import { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSWRConfig, mutate } from 'swr';

import Box from '@mui/material/Box';

import { authSelector } from 'store/slices/AuthSlice';
import { matchGlobalAuth, AUTH_ADMIN } from 'utils/matchAuth';

import HeaderMenu from './HeaderMenu';
import SignInDialog from './SignInDialog';
import UserDropdown from './UserDropdown';
import CipLogo from 'assets/images/cip.png';

import styles from './index.module.scss';

const pages = [
  {
    label: 'Projects',
    link: 'projects',
  },
  {
    label: 'People',
    link: 'people',
  },
  {
    label: 'Management',
    link: 'management',
    auth: AUTH_ADMIN,
  },
];

const Header = () => {
  const { user } = useSelector(authSelector);
  const { cache } = useSWRConfig();
  const authedPages = pages.filter((page) =>
    matchGlobalAuth(page.auth, user?.role)
  );

  // TODO: a better way to update authed data when sign in?
  useEffect(() => {
    [...cache.keys()].forEach((key) => {
      mutate(key);
    });
  }, [cache, user]);

  // menu
  return (
    <header className={styles.header}>
      <Link className={styles.header__logo} to="/">
        <img src={CipLogo} />
      </Link>
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        {authedPages.map((page) => (
          <NavLink
            className={({ isActive }) =>
              `${styles.header__link} ${isActive ? 'active' : undefined}`
            }
            key={page.link}
            to={page.link}
          >
            {page.label}
          </NavLink>
        ))}
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <HeaderMenu pages={authedPages} />
      </Box>
      {user ? <UserDropdown user={user} /> : <SignInDialog />}
    </header>
  );
};

export default Header;
