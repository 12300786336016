import useSWR from 'swr';
import fetcher from './fetcher';

export function usePeople() {
  const { data, error } = useSWR(`/api/people`, fetcher);

  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
  };
}

export function useUsers() {
  const { data, error, mutate } = useSWR(`/api/users`, fetcher);

  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
    mutate,
  };
}

export function useUser(userId) {
  const { data, error, mutate } = useSWR(
    userId ? `/api/users/${userId}` : null,
    fetcher
  );

  return {
    data: data,
    loading: !error && !data && userId,
    error: error?.message || null,
    mutate,
  };
}
