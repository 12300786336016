import { Routes, Route, Navigate } from 'react-router-dom';

import ManagementLayout from './ManagementLayout';
import Projects from './Projects';
import Tasks from './Tasks';
import Users from './Users';
import Access from './Access';

const pages = [
  {
    label: 'Add/Edit Projects',
    link: 'projects',
  },
  {
    label: 'Mangage Tasks',
    link: 'tasks',
  },
  {
    label: 'Manage Users',
    link: 'users',
  },
  {
    label: 'Project Access',
    link: 'access',
  },
];

const Management = () => {
  return (
    <Routes>
      <Route path="/*" element={<ManagementLayout pages={pages} />}>
        <Route index element={<Navigate replace to="projects" />} />
        <Route path="projects/*" element={<Projects />} />
        <Route path="tasks/*" element={<Tasks />} />
        <Route path="users/*" element={<Users />} />
        <Route path="access/*" element={<Access />} />
      </Route>
    </Routes>
  );
};

export default Management;
