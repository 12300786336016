import useSWR from 'swr';
import fetcher from './fetcher';

export function useProjects() {
  const { data, error, mutate } = useSWR(`/api/projects`, fetcher);

  return {
    data,
    loading: !error && !data,
    error: error || null,
    mutate,
  };
}

export function useProjectByUid(uid) {
  const { data, error, mutate } = useSWR(
    uid ? `/api/projects/${uid}` : null,
    fetcher
  );

  return {
    data,
    loading: uid && !error && !data,
    error: error || null,
    mutate,
  };
}

export function useProjectAcessByUid(uid) {
  const { data, error, mutate } = useSWR(
    uid ? `/api/projects/${uid}/access` : null,
    fetcher
  );

  return {
    data,
    loading: uid && !error && !data,
    error: error || null,
    mutate,
  };
}

export function useResearchAreas() {
  const { data, error } = useSWR(`/api/research-areas`, fetcher);
  return {
    data: data,
    loading: !error && !data,
    error: error || null,
  };
}

export function useUniversities() {
  const { data, error } = useSWR(`/api/universities`, fetcher);
  return {
    data: data,
    loading: !error && !data,
    error: error || null,
  };
}
