import { useParams, Routes, Route, Navigate } from 'react-router-dom';

import SubMenuLayout from '../SubMenuLayout';

import DetailsTable from './DetailsTable';
import DmpTable from './DmpTable';

const pages = [
  {
    label: 'Project Details',
    link: 'details',
  },
  {
    label: 'Data Management Plan',
    link: 'dmp',
  },
];

// UI component
const Overview = () => {
  const { uid } = useParams();
  return (
    <Routes>
      <Route path="/*" element={<SubMenuLayout pages={pages} />}>
        <Route index element={<Navigate replace to="details" />} />
        <Route path="details" element={<DetailsTable uid={uid} />} />
        <Route path="dmp" element={<DmpTable uid={uid} />} />
      </Route>
    </Routes>
  );
};

export default Overview;
