import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const authGoogle = createAsyncThunk(
  'auth/google',
  async (idToken, { dispatch, rejectWithValue }) => {
    try {
      await axios.post('/api/auth/google', {
        idToken: idToken,
      });
      dispatch(fetchCurrentUser());
    } catch (e) {
      if (e.response.status === 403) {
        return rejectWithValue('Email not registered');
      }
      return rejectWithValue(e.message);
    }
  }
);

export const authPassword = createAsyncThunk(
  'auth/password',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await axios.post('/api/auth/password', data);
      dispatch(fetchCurrentUser());
    } catch (e) {
      if (e.response.status === 403) {
        return rejectWithValue(e.response.data.errors?.message);
      }
      return rejectWithValue(e.message);
    }
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      await axios.post('/api/auth/signout');
      dispatch(fetchCurrentUser());
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/auth/current');
      const data = response.data;
      if (response.status >= 200 && response.status < 300) {
        return data;
      }
      return rejectWithValue(data);
    } catch (e) {
      if (e.response.status === 401) {
        return null;
      }
      return rejectWithValue(e.response.data);
    }
  }
);
const SignInPending = (state, { payload }) => {
  state.loading = true;
  state.error = null;
};
const SignInFail = (state, { payload }) => {
  state.user = null;
  state.loading = false;
  state.error = payload;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchCurrentUser.pending]: SignInPending,
    [fetchCurrentUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    },
    [fetchCurrentUser.rejected]: SignInFail,
    [authGoogle.pending]: SignInPending,
    [authGoogle.rejected]: SignInFail,
    [authPassword.pending]: SignInPending,
    [authPassword.rejected]: SignInFail,
  },
});

export const { clearState, beginSignIn } = authSlice.actions;

export const authSelector = (state) => state.auth;
