import { useParams, Routes, Route, Navigate } from 'react-router-dom';

import SubMenuLayout from '../SubMenuLayout';
import TasksContent from 'components/TasksConent';
import { useProjectTasks } from 'store/swr/useTasks';

const pages = [
  {
    label: 'Quarterly Report',
    link: 'quarterly-report',
  },
  {
    label: 'Deliverables',
    link: 'deliverables',
  },
  {
    label: 'Datasets',
    link: 'datasets',
  },
  {
    label: 'Outreach Effort',
    link: 'outreach-effort',
  },
];

const Tasks = () => {
  const { uid } = useParams();
  const apiData = useProjectTasks(uid);

  return (
    <Routes>
      <Route path="/*" element={<SubMenuLayout pages={pages} />}>
        <Route index element={<Navigate replace to="quarterly-report" />} />
        {pages.map((page) => (
          <Route
            key={page.link}
            path={page.link}
            element={
              <TasksContent
                category={page.link}
                showProject={false}
                apiData={apiData}
              />
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Tasks;
