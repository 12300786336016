import PropTypes from 'prop-types';
import { useParams, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useProjectByUid } from 'store/swr/useProjects';
import SubNav from 'components/SubNav';
import Error from 'pages/Error';

// UI component
const ProjectLayout = ({ pages }) => {
  const { uid } = useParams();
  const { data: { project } = {}, error } = useProjectByUid(uid);

  return error ? (
    <Error status={error.status} />
  ) : (
    <Container xs={{ margin: '2.4rem' }}>
      <Box component="h1" sx={{ padding: '2rem 0 0', maxWidth: 'md' }}>
        {project?.title}
      </Box>
      <Box component="p" sx={{ padding: '1rem 0 0' }}>
        {project?.PI.name && `PI: ${project?.PI.name}`}
      </Box>
      {project?.coPIs && project?.coPIs.length > 0 && (
        <Box component="p" sx={{ padding: '1rem 0 0' }}>
          <span>Co-PIs: </span>
          {project.coPIs.map((p, index) => (
            <span key={p._id}>
              {index > 0 ? ', ' : null}
              {p.name}
            </span>
          ))}
        </Box>
      )}
      <Box sx={{ paddingBottom: '1.6rem' }}></Box>
      <SubNav pages={pages} />
      <Outlet />
    </Container>
  );
};

ProjectLayout.propTypes = {
  // project: PropTypes.object.isRequired,
  // uid: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProjectLayout;
