import { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import UserSelectForm from 'forms/UserSelectForm';

const AddButton = ({ uid, mutate }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterSubmit = () => {
    mutate();
    handleClose();
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
          onClick={handleClickOpen}
          disabled={!uid}
          endIcon={<AddIcon />}
        >
          Add Access
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="edit-user-access"
          aria-describedby="edit-user-access"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Add Access</DialogTitle>
          <DialogContent>
            <UserSelectForm uid={uid} afterSubmit={afterSubmit} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

AddButton.propTypes = {
  uid: PropTypes.string,
  mutate: PropTypes.func.isRequired,
};
export default AddButton;
