import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { usePeople } from 'store/swr/usePeople';
import { matchUser, unmatchUser } from 'services/apis';
import { Form, AutoComplete, SubmitButton } from 'components/Form';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    person: yup
      .object({
        _id: yup
          .string()
          .typeError('Please select a person')
          .required('Please select a person'),
      })
      .typeError('Please select a person'),
  })
  .required();

const UserMatchForm = ({ user, person, afterSubmit }) => {
  const { data: { people = [] } = {}, loading, error } = usePeople();

  const onSubmit = async (data) => {
    try {
      const userId = user._id;
      const personId = data.person._id;
      if (person) {
        await unmatchUser(person._id);
      }
      await matchUser(userId, personId);
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderUserCard = (user) => {
    return (
      <Paper variant="outlined" elevation={0} sx={{ padding: '1.6rem 2.4rem' }}>
        <p>{user.name}</p>
        <p>{user.email}</p>
      </Paper>
    );
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: { person },
          resolver: yupResolver(schema),
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            {renderUserCard(user)}
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              name="person"
              label="Select Person"
              options={people}
              getOptionLabel={(option) => `${option.name} - ${option.email}`}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

UserMatchForm.propTypes = {
  user: PropTypes.object.isRequired,
  person: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};

export default UserMatchForm;
