import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import AppLayout from './AppLayout';
import Banner from './Banner';
import Projects from './Projects';
import People from './People';
import Project from 'pages/Project';
import Management from 'pages/Management';
import Error, { STATUS_NOT_AUTHORIZED, STATUS_NOT_FOUND } from 'pages/Error';

import { fetchProjects } from 'store/slices/ProjectsSlice';
import { fetchCurrentUser } from 'store/slices/AuthSlice';
import { AUTH_ADMIN } from 'utils/matchAuth';
import AuthorizedGlobal from 'components/AuthorizedGlobal';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
    dispatch(fetchCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<Banner />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:uid/*" element={<Project />} />
        <Route path="people" element={<People />} />
        <Route
          path="management/*"
          element={
            <AuthorizedGlobal
              authority={AUTH_ADMIN}
              noMatch={<Error status={STATUS_NOT_AUTHORIZED} />}
            >
              <Management />
            </AuthorizedGlobal>
          }
        />
        <Route path="*" element={<Error status={STATUS_NOT_FOUND} />} />
      </Route>
    </Routes>
  );
};

export default Home;
