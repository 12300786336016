import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useUsers } from 'store/swr/usePeople';
import { matchUser } from 'services/apis';
import { Form, AutoComplete, SubmitButton } from 'components/Form';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    user: yup
      .object({
        _id: yup
          .string()
          .typeError('Please select a user')
          .required('Please select a user'),
      })
      .typeError('Please select a user'),
  })
  .required();

const UserMatchForm = ({ person, afterSubmit }) => {
  const { data: { users = [] } = {}, loading, error } = useUsers();

  const onSubmit = async (data) => {
    try {
      const userId = data.user._id;
      const personId = person._id;
      await matchUser(userId, personId);
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderPersonCard = (person) => {
    return (
      <Paper variant="outlined" elevation={0} sx={{ padding: '1.6rem 2.4rem' }}>
        <p>
          {person.name} {person.authorName}
        </p>
        <p>{person.email}</p>
      </Paper>
    );
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: users.find((user) => user.email === person.email),
          resolver: yupResolver(schema),
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            {renderPersonCard(person)}
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              name="user"
              label="Select User"
              options={users}
              getOptionLabel={(option) => `${option.name} - ${option.email}`}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

UserMatchForm.propTypes = {
  person: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};

export default UserMatchForm;
