import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stepper from './components/Stepper';

const ManageProjects = ({ pages }) => {
  // null for new project, undefined for nothing selected
  const [activeProject, setActiveProject] = useState(undefined);
  const [isFormDirty, setIsFormtDirty] = useState(false);

  return (
    <>
      <Box component="h2" sx={{ padding: '1rem 0 2rem' }}>
        {activeProject ? activeProject.title : 'Add / Edit Project and Tasks'}
      </Box>
      <Stepper
        pages={pages}
        context={{
          activeProject,
          setActiveProject,
          isFormDirty,
          setIsFormtDirty,
        }}
      />
      {/* <FormSaveDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        handleProceed={() => {
          setActiveStep(stagedStep);
        }}
      /> */}
    </>
  );
};

ManageProjects.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManageProjects;
