import axios from 'axios';
import toast from 'react-hot-toast';

export const makeFormData = (data) => {
  const formData = new FormData();

  const addValue = (key, item) => {
    if (item instanceof File) {
      formData.append(key, item, item.name);
    } else {
      formData.append(key, item);
    }
  };

  for (const key in data) {
    const val = data[key];
    if (Array.isArray(val)) {
      val.forEach((item) => {
        addValue(key, item);
      });
    } else {
      addValue(key, val);
    }
  }

  return formData;
};

export const sendAsFormData = async (data, api) => {
  console.log(data);

  const formData = makeFormData(data);
  try {
    const res = await fetch(api, {
      method: 'POST',
      body: formData,
    });
    return await res.json();
  } catch (err) {
    toast.error(err.toString());
    return err;
  }
};

export const sendAsJSON = async (data, url, method = 'POST') => {
  console.log(data);

  try {
    await axios({
      url,
      method,
      data,
    });
    return null;
  } catch (err) {
    toast.error(err.toString());
    return err;
  }
};
