import useSWR from 'swr';
import fetcher from './fetcher';

export function useTasks() {
  const { data, error, mutate } = useSWR(`/api/tasks`, fetcher);
  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
    mutate: mutate,
  };
}

export function useProjectTasks(uid) {
  const { data, error, mutate } = useSWR(`/api/projects/${uid}/tasks`, fetcher);

  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
    mutate: mutate,
  };
}

export function useTaskForm(taskId) {
  const { data, error, mutate } = useSWR(
    taskId ? `/api/tasks/${taskId}/progress-form` : null,
    fetcher
  );

  return {
    data: data,
    loading: !error && !data && !!taskId,
    error: error?.message || null,
    mutate: mutate,
  };
}

// related documents
export function useProjectDocuments(uid) {
  const { data, error, mutate } = useSWR(
    `/api/projects/${uid}/documents`,
    fetcher
  );

  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
    mutate: mutate,
  };
}

// single document form
export function useDocument(docuemntId) {
  const { data, error, mutate } = useSWR(
    docuemntId ? `/api/documents/${docuemntId}` : null,
    fetcher
  );

  return {
    data: data,
    loading: !error && !data && !!docuemntId,
    error: error?.message || null,
    mutate: mutate,
  };
}

export function useStaticDocuments() {
  const { data, error, mutate } = useSWR('/api/static-documents', fetcher);

  return {
    data: data,
    loading: !error && !data,
    error: error?.message || null,
    mutate: mutate,
  };
}
