import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import SubNav from 'components/SubNav';

// UI component
const SubMenuLayout = ({ pages }) => {
  return (
    <>
      <SubNav pages={pages} level={1} />
      <Outlet />
    </>
  );
};

SubMenuLayout.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object),
};

export default SubMenuLayout;
