import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCurrentUser, authSelector } from 'store/slices/AuthSlice';
import { matchGlobalAuth } from 'utils/matchAuth';
import LoadingIcon from 'components/Loading';

const AuthorizedGobal = ({ authority, children, noMatch }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector(authSelector);
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  return loading ? (
    <LoadingIcon />
  ) : matchGlobalAuth(authority, user?.role) ? (
    children
  ) : (
    noMatch
  );
};

AuthorizedGobal.propTypes = {
  authority: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  noMatch: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default AuthorizedGobal;
