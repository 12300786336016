import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useForm, useFormContext, useFieldArray } from 'react-hook-form';

import { useProjectTasks } from 'store/swr/useTasks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { updateProjectTasks } from 'services/apis';
import {
  MethodForm,
  Input,
  DatePicker,
  AutoComplete,
  SubmitButton,
  BooleanCheckBox,
} from 'components/Form';
import GridCard from 'components/Form/GridCard';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    tasks: yup
      .array()
      .of(
        yup.object().shape({
          item: yup.string().required().required('Please enter a value'),
          category: yup.string().required().typeError('Please enter a value'),
          subCategory: yup.string().nullable(),
          status: yup.string().required().typeError('Please enter a value'),
          deadline: yup.date().required().typeError('Please enter a value'),
          isPublic: yup.boolean(),
        })
      )
      .required(),
  })
  .required();

const statusOptions = ['open', 'submitted', 'approved'];
const cateOptions = [
  'quarterly-report',
  'deliverables',
  'datasets',
  'outreach-effort',
];
const subCateOptions = ['quarterly-report', 'final-report'];

const genEmptyTask = () => {
  return {
    item: null,
    category: null,
    subCategory: null,
    status: null,
    deadline: null,
    isPublic: false,
  };
};

const TaskFields = ({ addRemoved }) => {
  const { control } = useFormContext();
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  const onAdd = () => {
    prepend(genEmptyTask());
  };

  const onDelete = (taskId, index) => {
    addRemoved(taskId);
    remove(index);
  };

  return (
    <Grid item xs={12}>
      <Grid sx={{ marginBottom: '1.6rem' }}>
        <Button variant="contained" onClick={onAdd}>
          Add New Task
        </Button>
      </Grid>

      <Box xs={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 840,
              '& td': { padding: '0 4px' },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0,
                border: 'none',
              },
              '& .MuiFormControl-root': {
                marginTop: '4px',
                marginBottom: '4px',
              },
            }}
            aria-label="tasks"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>SubCategory</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Deadline</TableCell>
                <TableCell>Public</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item, index) => {
                return (
                  <TableRow key={item._id || index}>
                    <TableCell sx={{ minWidth: '24rem' }}>
                      <Input name={`tasks.${index}.item`} multiline required />
                    </TableCell>
                    <TableCell sx={{ width: '20rem' }}>
                      <AutoComplete
                        name={`tasks.${index}.category`}
                        options={cateOptions}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        required
                      />
                    </TableCell>
                    <TableCell sx={{ width: '20rem' }}>
                      <AutoComplete
                        name={`tasks.${index}.subCategory`}
                        options={subCateOptions}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: '16rem' }}>
                      <AutoComplete
                        name={`tasks.${index}.status`}
                        options={statusOptions}
                        getOptionLabel={(option) => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        required
                      />
                    </TableCell>
                    <TableCell sx={{ width: '21rem' }}>
                      <DatePicker name={`tasks.${index}.deadline`} required />
                    </TableCell>
                    <TableCell sx={{ width: '6rem' }}>
                      <BooleanCheckBox name={`tasks.${index}.isPublic`} />
                    </TableCell>
                    <TableCell sx={{ width: '6rem' }}>
                      <Button
                        color="error"
                        onClick={() => onDelete(item._id, index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

const ProjectTasksForm = ({ uid, setDirty, afterSubmit }) => {
  const {
    data: { tasks = [] } = {},
    loading,
    error,
    mutate,
  } = useProjectTasks(uid);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { tasks },
    resolver: yupResolver(schema),
  });

  const { reset } = methods;
  useEffect(() => {
    reset({ tasks });
  }, [reset, tasks]);

  const removed = useRef([]);
  const addRemoved = (id) => {
    removed.current.push(id);
  };

  const onSubmit = async (data) => {
    try {
      const tasks = await updateProjectTasks(uid, data.tasks, removed.current);
      mutate(); // reset
      afterSubmit(tasks);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <MethodForm onSubmit={onSubmit} methods={methods} setDirty={setDirty}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <GridCard label="Project Tasks">
            <TaskFields addRemoved={addRemoved} />
          </GridCard>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </MethodForm>
    </Loading>
  );
};

TaskFields.propTypes = {
  addRemoved: PropTypes.func.isRequired,
};

ProjectTasksForm.propTypes = {
  uid: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
  setDirty: PropTypes.func,
};

export default ProjectTasksForm;
