import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/AuthSlice';
import { projectsSlice } from './slices/ProjectsSlice';

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    projects: projectsSlice.reducer,
  },
});
