import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Container from '@mui/material/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';

const AppLayout = () => {
  const location = useLocation();
  return (
    <>
      <Header />
      <Container
        disableGutters
        maxWidth={location.pathname === '/' ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </Container>
      <Footer />
      <Toaster />
    </>
  );
};

export default AppLayout;
