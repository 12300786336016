import { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import UserMatchForm from 'forms/UserMatchForm';
import PersonMatchForm from 'forms/PersonMatchForm';
const AssignButton = ({ person, user, mutate }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterSubmit = () => {
    mutate();
    handleClose();
  };

  return (
    <>
      <div>
        <span>Not Assigned</span>
        <IconButton
          color="primary"
          aria-label="assign button"
          component="span"
          size="small"
          sx={{ padding: 0, marginLeft: '0.4rem', height: '1.4rem' }}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="edit-user-person-connection"
          aria-describedby="edit-user-person-connection"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{person ? 'Match User' : 'Match Person'}</DialogTitle>
          <DialogContent>
            {person ? (
              <UserMatchForm person={person} afterSubmit={afterSubmit} />
            ) : (
              <PersonMatchForm user={user} afterSubmit={afterSubmit} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

AssignButton.propTypes = {
  person: PropTypes.object,
  user: PropTypes.object,
  mutate: PropTypes.func.isRequired,
};
export default AssignButton;
