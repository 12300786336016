import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import { useTasks } from 'store/swr/useTasks';
import TasksContent from 'components/TasksConent';
import AsyncAutoComplete from 'components/AsyncAutoComplete';

import DropdownSelect from './DropdownSelect';

const categoryOptions = [
  {
    label: 'All Categories',
    value: 'all',
  },
  {
    label: 'Quarterly Report',
    value: 'quarterly-report',
  },
  {
    label: 'Deliverables',
    value: 'deliverables',
  },
  {
    label: 'Datasets',
    value: 'datasets',
  },
  {
    label: 'Outreach Effort',
    value: 'outreach-effort',
  },
];

const statusOptions = [
  {
    label: 'All Status',
    value: 'all',
  },
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'Submitted',
    value: 'submitted',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
];

const ManageTasks = () => {
  const [activeProject, setActiveProject] = useState(null);
  const [activeCate, setActiveCate] = useState('all');
  const [activeStatus, setActiveStatus] = useState('all');
  const apiData = useTasks();

  const customFilter = (task) => {
    // console.log(task, activeProject);
    return (
      (!activeProject || activeProject.title === task.project.title) &&
      (activeCate === 'all' || activeCate === task.category) &&
      (activeStatus === 'all' || activeStatus === task.status)
    );
  };
  return (
    <div>
      <Box sx={{ display: 'flex', gap: '2rem', marginBottom: '2.4rem' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AsyncAutoComplete
            value={activeProject}
            setValue={setActiveProject}
          />
        </Box>
        <Box sx={{ width: '20rem' }}>
          <DropdownSelect
            value={activeCate}
            setValue={setActiveCate}
            options={categoryOptions}
          />
        </Box>
        <Box sx={{ width: '20rem' }}>
          <DropdownSelect
            value={activeStatus}
            setValue={setActiveStatus}
            options={statusOptions}
          />
        </Box>
        {/* <Box>
          <Button
            variant="contained"
            sx={{
              width: '10rem',
              fontSize: '1.2rem',
              height: '100%',
            }}
            startIcon={<EditIcon />}
            onClick={() => {}}
          >
            Edit Tasks
          </Button>
        </Box> */}
      </Box>
      <TasksContent
        category="all"
        showProject={true}
        apiData={apiData}
        customFilter={customFilter}
      />
    </div>
  );
};
export default ManageTasks;
