import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';

const TableStyled = ({ label, children }) => {
  return (
    <TableContainer>
      <Table
        aria-label={label}
        sx={{ marginBottom: '2.4rem', maxWidth: '80rem' }}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

TableStyled.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default TableStyled;
