import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import { projectsSelector } from 'store/slices/ProjectsSlice';
import Loading from 'components/Loading';
import ProjectCard from 'components/ProjectCard';
import InfoCard from 'components/InfoCard';

const Projects = () => {
  const projects = useSelector(projectsSelector.selectAll);
  const loading = useSelector((state) => state.projects.loading);
  const error = useSelector((state) => state.projects.error);

  return (
    <Loading loading={loading} error={error}>
      <Grid container spacing={2} sx={{ padding: '2rem' }}>
        {projects.map((project) => (
          <Grid key={project.uid} item xs={12} sm={6}>
            <ProjectCard item={project}></ProjectCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <InfoCard
            text={
              projects.length
                ? `Found ${projects.length} Projects`
                : 'No Project Found'
            }
          />
        </Grid>
      </Grid>
    </Loading>
  );
};

export default Projects;
