import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { TransitionGroup } from 'react-transition-group';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './links.module.scss';

const validateUrl = (str) => {
  const expression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);

  if (!str.match(regex)) {
    return false;
  }

  try {
    return new URL(str);
  } catch (e) {
    return false;
  }
};

const Links = ({ name, label, ...rest }) => {
  const [links, setLinks] = useState([]);
  const [initLinks, setInitLinks] = useState([]);
  const [removedLinks, setRemovedLinks] = useState([]);
  const inputRef = useRef();
  const { setValue, getValues } = useFormContext();
  const fullName = `links.${name}`;

  const handleAddLink = (link) => {
    const url = validateUrl(link);
    if (!url) {
      toast.error('Please enter a valid URL');
      return;
    }
    if (
      links.includes(url.href) ||
      initLinks.map((item) => item.link).includes(url.href)
    ) {
      toast.error('URL already exists');
      return;
    }
    // ref.current.value = ''; // well this is not gonna rerender
    setLinks((links) => {
      return [...links, url.href];
    });
  };
  const handleDelete = (deleted) => {
    setLinks((links) => links.filter((link) => link !== deleted));
  };
  const handleDeleteInit = (deleted) => {
    setInitLinks((links) => links.filter((link) => link._id !== deleted._id));
    setRemovedLinks((removedLinks) => [...removedLinks, deleted._id]);
  };

  // set initial values
  useEffect(() => {
    setInitLinks((links) => {
      const defaultValues = getValues(fullName);
      if (Array.isArray(defaultValues)) {
        return defaultValues;
      } else {
        return links;
      }
    });
  }, [fullName, getValues]);

  // update new links in form data
  useEffect(() => {
    setValue(fullName, links);
  }, [links, fullName, setValue]);

  // update removed links in form data
  useEffect(() => {
    setValue(`linksRemoved.${name}`, removedLinks);
  }, [removedLinks, name, setValue]);

  return (
    <>
      <TextField
        size="small"
        margin="dense"
        fullWidth
        inputRef={inputRef}
        variant="standard"
        placeholder="https://zenodo.org/record/5738251 # Press enter to add URL."
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleAddLink(e.target.value);
          }
        }}
      />
      <ul className={styles.filelist}>
        <TransitionGroup>
          {initLinks.map((item) => (
            <Collapse key={item._id}>
              <Chip
                className={styles.chip}
                size="small"
                onDelete={() => {
                  handleDeleteInit(item);
                }}
                deleteIcon={<DeleteIcon />}
                label={<span>{item.link}</span>}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        <TransitionGroup>
          {links.map((item) => (
            <Collapse key={item}>
              <Chip
                className={styles.chip}
                size="small"
                onDelete={() => {
                  handleDelete(item);
                }}
                deleteIcon={<DeleteIcon />}
                label={<span>{item}</span>}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </ul>
    </>
  );
};
Links.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default Links;
