import axios from 'axios';

const fetcher = async (url) => {
  let res;
  try {
    res = await axios.get(url);
  } catch (err) {
    if (err.response) {
      const message = err.response.errors?.message || err.message;
      const returnErr = new Error(message);
      returnErr.status = err.response.status;
      throw returnErr;
    }
    throw err;
  }
  return res.data;
};

export default fetcher;
