import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIcon = () => {
  return (
    <Box
      sx={{ width: '100%', flexGrow: 1, display: 'grid', placeItems: 'center' }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingIcon;
