import PropTypes from 'prop-types';

import styles from './index.module.scss';

const ProjectCard = ({ item }) => {
  return (
    <section className={styles.card}>
      <h3 className={styles.card__title}>{item.name}</h3>
      <p className={styles.card__line}>{item.type}</p>
      <p className={styles.card__line}>{item.email}</p>
    </section>
  );
};

ProjectCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ProjectCard;
