import { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import Paper from '@mui/material/Paper';

import { deleteProjectAccess } from 'services/apis';

const DeleteButton = ({ uid, user, mutate }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteProjectAccess(uid, user._id);
      mutate();
      handleClose();
    } catch (err) {
      toast.error(err.meassage);
    }
  };

  return (
    <>
      <IconButton
        // color="primary"
        aria-label="assign button"
        component="span"
        size="small"
        sx={{ padding: 0, marginLeft: '0.8rem', height: '1.4rem' }}
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-user-access"
        aria-describedby="edit-user-access"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Disable Access</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to disable user&apos;s access to this project?
          </DialogContentText>
          <Paper
            variant="outlined"
            elevation={0}
            sx={{ padding: '1.6rem 2.4rem', marginTop: '1.6rem' }}
          >
            <p>
              {user.name} - {user.email}
            </p>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButton.propTypes = {
  uid: PropTypes.string,
  user: PropTypes.object,
  mutate: PropTypes.func.isRequired,
};
export default DeleteButton;
