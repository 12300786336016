// import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';

import TextField from '@mui/material/TextField';

// get value by nexted key
const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

const Input = ({ name, label, ...others }) => {
  const { register } = useFormContext();
  const { errors } = useFormState();
  const myErrors = get(errors, name);

  return (
    <TextField
      {...register(name)}
      name={name}
      label={label}
      error={!!myErrors}
      helperText={myErrors?.message ?? ''}
      size="small"
      margin="dense"
      fullWidth
      {...others}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default Input;
