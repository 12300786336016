import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useProjectAcessByUid } from 'store/swr/useProjects';
import Loading from 'components/Loading';
import AssignButton from './AssignButton';
import AddButton from './Addbutton';
import DeleteButton from './DeleteButton';

const TableSection = ({ title, items, mutate, uid, renderDelete = false }) => {
  const renderPersonCell = (item) => {
    return item.person ? (
      <>{item.person.name}</>
    ) : (
      <AssignButton person={item.person} user={item.user} mutate={mutate} />
    );
  };

  const renderUserCell = (item) => {
    return item.user ? (
      <div>
        <span>{item.user.email}</span>
        {renderDelete && uid && (
          <DeleteButton uid={uid} mutate={mutate} user={item.user} />
        )}
      </div>
    ) : (
      <AssignButton person={item.person} user={item.user} mutate={mutate} />
    );
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <b>{title}</b>
            </TableCell>
          </TableRow>
          {items.length !== 0 && (
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontSize: '1.2rem',
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: 'var(--color-text-accent)',
                },
              }}
            >
              <TableCell size="small" sx={{ width: '50%' }}>
                User
              </TableCell>
              <TableCell size="small" sx={{ width: '50%' }}>
                Person
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {items.length === 0 ? (
            <TableRow>
              <TableCell
                align="center"
                colSpan={3}
                sx={{ color: 'var(--color-text-accent)' }}
              >
                No Data
              </TableCell>
            </TableRow>
          ) : (
            items.map((item) => (
              <TableRow key={item.person?._id || item.user?._id}>
                <TableCell>{renderUserCell(item)}</TableCell>
                <TableCell>{renderPersonCell(item)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ProjectAccessTable = ({ uid }) => {
  const {
    data: { access: { PI = [], coPIs = [], researchers = [] } = {} } = {},
    loading,
    error,
    mutate,
  } = useProjectAcessByUid(uid);

  return (
    <Loading loading={loading} error={error}>
      <TableSection title="PI" items={PI} mutate={mutate} />
      <TableSection title="Co-PIs" items={coPIs} mutate={mutate} />
      <TableSection
        title="Researchers"
        items={researchers}
        mutate={mutate}
        uid={uid}
        renderDelete={true}
      />
      <Box sx={{ marginTop: '1.6rem', marginLeft: '1.6rem' }}>
        <AddButton uid={uid} mutate={mutate} />
      </Box>
    </Loading>
  );
};

TableSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  mutate: PropTypes.func.isRequired,
  uid: PropTypes.string,
  renderDelete: PropTypes.bool,
};

ProjectAccessTable.propTypes = {
  uid: PropTypes.string,
};

export default ProjectAccessTable;
