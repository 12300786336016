import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

// middleward actions
export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get('/api/projects');
      const projects = res?.data?.projects;
      if (projects) {
        return projects;
      }
      rejectWithValue('no projects found');
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

const projectsAdapter = createEntityAdapter({
  selectId: (item) => item._id,
});

// slice
export const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchProjects.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchProjects.fulfilled]: (state, action) => {
      // console.log(action.payload);
      const projectEntries = action.payload;
      projectsAdapter.setAll(state, projectEntries);
      state.loading = false;
    },
    [fetchProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// action
export const { actions } = projectsSlice;

// selector
export const projectsSelector = projectsAdapter.getSelectors(
  (state) => state.projects
);
