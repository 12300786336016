import { useState } from 'react';
import PropTypes from 'prop-types';

// import { useProjectTasks } from 'store/swr/useTasks';
import Loading from 'components/Loading';
import Drawer from 'components/Drawer';
import QRActionForm from 'forms/QRActionForm';
import UploadForm from 'forms/UploadForm';
import DatasetsForm from 'forms/DatasetsForm';

import DataGridStyled, {
  getTaskFields,
  getTaskColumns,
  getTaskRows,
} from 'components/TasksDataGrid';

const Form = ({ category, subCategory, taskId, afterSubmit }) => {
  switch (category) {
    case 'quarterly-report': {
      if (subCategory === 'quarterly-report') {
        return <QRActionForm taskId={taskId} afterSubmit={afterSubmit} />;
      } else {
        return <UploadForm taskId={taskId} afterSubmit={afterSubmit} />;
      }
    }
    case 'datasets': {
      return <DatasetsForm taskId={taskId} afterSubmit={afterSubmit} />;
    }
    default: {
      return <UploadForm taskId={taskId} afterSubmit={afterSubmit} />;
    }
  }
};

const Table = ({
  category,
  showProject,
  apiData,
  customFilter = () => true,
}) => {
  const {
    data: { tasks = [], auth = null } = {},
    loading,
    error,
    mutate,
  } = apiData;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };
  const onEdit = (para) => {
    setActiveRow(para.row);
    toggleDrawer(true);
  };
  const afterSubmit = () => {
    setActiveRow(null);
    mutate(); // update tasks data
    toggleDrawer(false);
  };
  const taskFields = getTaskFields(category, auth, showProject);
  const columns = getTaskColumns(taskFields, auth, onEdit);
  const rows = getTaskRows(
    tasks
      ?.filter((task) => {
        return (
          (category === 'all' || task.category === category) &&
          customFilter(task)
        );
      })
      .sort((a, b) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      }),
    taskFields
  );
  // const getTaskCategory

  return (
    <Loading loading={loading} error={error}>
      <DataGridStyled rows={rows} columns={columns} />
      <Drawer
        open={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        header={activeRow?.item}
      >
        <Form
          category={activeRow?.category}
          subCategory={activeRow?.subCategory}
          taskId={activeRow?.id}
          afterSubmit={afterSubmit}
        />
      </Drawer>
    </Loading>
  );
};

Form.propTypes = {
  category: PropTypes.string,
  subCategory: PropTypes.string,
  taskId: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
};

Table.propTypes = {
  // uid: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  showProject: PropTypes.bool,
  apiData: PropTypes.object,
  customFilter: PropTypes.func,
};

export default Table;
