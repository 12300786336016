import PropTypes from 'prop-types';

import styles from './index.module.scss';

const InfoCard = ({ text }) => {
  return (
    <section className={styles.card}>
      <p className={styles.card__line}>{text}</p>
    </section>
  );
};

InfoCard.propTypes = {
  text: PropTypes.string,
};

export default InfoCard;
