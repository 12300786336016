import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTaskForm } from 'store/swr/useTasks';
import { updateTaskProgress } from 'services/apis';

import Grid from '@mui/material/Grid';
import { Form, Links, Upload, SubmitButton } from 'components/Form';
import GridCard from 'components/Form/GridCard';
import Loading from 'components/Loading';

const DatasetsForm = ({ taskId, afterSubmit }) => {
  const { data, loading, error, mutate } = useTaskForm(taskId);

  const onSubmit = async (data) => {
    try {
      await updateTaskProgress(taskId, data);
      mutate();
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: data,
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <GridCard label="Link to External Data">
            <Grid item xs={12}>
              <Links name="dataLink" label="Link to data" />
            </Grid>
          </GridCard>

          <Grid item xs={12}>
            <Upload
              name="files"
              label="Upload Documents"
              action={`/api/tasks/${taskId}/upload`}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

DatasetsForm.propTypes = {
  taskId: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
};

export default DatasetsForm;
