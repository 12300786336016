import styles from './index.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <>&copy;</>
      {`${new Date().getFullYear()} `}
      <a
        className={styles.footer__link}
        href="https://c2smart.engineering.nyu.edu/"
        target="_blank"
        rel="noreferrer"
      >
        C2SMART
      </a>
      {' All Rights Reserved'}
    </footer>
  );
};

export default Footer;
