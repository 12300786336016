import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

import { StringAvatar } from 'components/Avatar';
import { signOut } from 'store/slices/AuthSlice';

const UserDropdown = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handlSignOut = async () => {
    await dispatch(signOut());
    navigate('/');
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handlSignOut}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <Fragment>
      <Button
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <StringAvatar name={user.name} />
      </Button>
      {renderMenu}
    </Fragment>
  );
};

UserDropdown.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserDropdown;
