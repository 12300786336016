import { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DocumentsForm from 'forms/DocumentsForm';
import { useProjectDocuments } from 'store/swr/useTasks';
import { deleteDocument } from 'services/apis';

import DataGridStyled, {
  getTaskFields,
  getTaskColumns,
  getTaskRows,
} from 'components/TasksDataGrid';
import AddButton from 'components/AddButton';
import Drawer from 'components/Drawer';
import Loading from 'components/Loading';
import DownloadPopup from './DownloadPopup';

const Table = ({ uid }) => {
  const {
    data: { documents = [], auth = null } = {},
    loading,
    error,
    mutate,
  } = useProjectDocuments(uid);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };
  const onEdit = (para) => {
    setActiveRow(para.row);
    toggleDrawer(true);
  };
  const afterSubmit = () => {
    setActiveRow(null);
    mutate(); // update tasks data
    toggleDrawer(false);
  };

  const handleDeleteConfirm = (params) => {
    setActiveRow(params.row);
    setIsConfirmOpen(true);
  };

  const handleDelete = async () => {
    const userId = activeRow._id;
    try {
      await deleteDocument(userId);
      mutate();
      setActiveRow(null);
      setIsConfirmOpen(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fields = getTaskFields('documents', auth);
  const columns = getTaskColumns(fields, auth, onEdit, handleDeleteConfirm);
  const rows = getTaskRows(documents);
  // const getTaskCategory

  return (
    <Loading loading={loading} error={error}>
      <Box
        sx={{
          marginBottom: '2.4rem',
          display: 'flex',
          gap: '1.6rem',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <AddButton onClick={onEdit} label="Add Docuemnt" />
        <DownloadPopup label={'Download guidelines and templates'} />
      </Box>
      <DataGridStyled rows={rows} columns={columns} />
      <Drawer
        open={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        header={activeRow?.item}
      >
        <DocumentsForm
          projectUid={uid}
          documentId={activeRow?.id}
          afterSubmit={afterSubmit}
        />
      </Drawer>
      <Dialog
        open={isConfirmOpen}
        onClose={() => {
          setIsConfirmOpen(false);
        }}
      >
        <DialogTitle>Are you sure to delte this item?</DialogTitle>
        <DialogContent>
          <DialogContentText>{activeRow?.item}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmOpen(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Loading>
  );
};

Table.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default Table;
