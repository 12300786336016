import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';

// MethodForm takes method as prop
// From takes useFormParam as prop and call useForm inside it self
// is there a better way to use methods outside of form?
export const MethodForm = ({
  onSubmit,
  children,
  methods, // const methods = useForm(useFormParam);
  setDirty = () => {},
  ...other
}) => {
  // const methods = useForm(useFormParam);

  useEffect(() => {
    setDirty(methods.formState.isDirty);
  }, [methods.formState, setDirty]);

  return (
    <FormProvider {...methods}>
      <form {...other} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export const Form = ({
  onSubmit,
  children,
  useFormParam = undefined, // const methods = useForm(useFormParam);
  setDirty = () => {},
  ...other
}) => {
  const methods = useForm(useFormParam);

  useEffect(() => {
    setDirty(methods.formState.isDirty);
  }, [methods.formState, setDirty]);

  return (
    <FormProvider {...methods}>
      <form {...other} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  useFormParam: PropTypes.object,
  setDirty: PropTypes.func,
};

MethodForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  methods: PropTypes.object.isRequired,
  setDirty: PropTypes.func,
};

export { default as Input } from './Input.jsx';
export { default as Password } from './Password.jsx';
export { default as Percent } from './Percent.jsx';
export { default as Radio } from './Radio.jsx';
export { default as DatePicker } from './DatePicker.jsx';
export { default as ChipSelect } from './ChipSelect.jsx';
export { default as AutoComplete } from './AutoComplete.jsx';
export { default as SubmitButton } from './SubmitButton.jsx';
export { default as BooleanCheckBox } from './BooleanCheckBox.jsx';
export { default as Upload } from './Upload';
export { default as Links } from './Links';
