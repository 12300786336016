import PropTypes from 'prop-types';
import styles from './fileLinks.module.scss';

// const fields = ['dataLink'];

const getFiles = (row) => {
  if (!row?.links) {
    return [];
  }
  const fileWrapper = row.links;
  let files = [];
  Object.keys(fileWrapper).forEach((field) => {
    files = [...files, ...fileWrapper[field]];
  });
  return files;
};

const Links = ({ row }) => {
  // console.log(row);

  const files = getFiles(row.uploads);
  if (!files) {
    return null;
  }
  return (
    <ul className={styles.filelist}>
      {files.map((item) => (
        <li key={item._id} className={styles['filelist__item']}>
          <a
            target="_blank"
            className={styles['filelist__link']}
            href={item.link}
            rel="noreferrer"
          >
            <p style={{ width: '100%' }}>{item.link}</p>
          </a>
        </li>
      ))}
    </ul>
  );
};

Links.propTypes = {
  row: PropTypes.object.isRequired,
};

export default Links;
