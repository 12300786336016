import PropTypes from 'prop-types';
import { useRef, useMemo, useState, useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { sendAsFormData } from 'utils/formdata';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { TransitionGroup } from 'react-transition-group';

import styles from './upload.module.scss';

const Upload = ({ name, label, action, ...rest }) => {
  const [files, setFiles] = useState([]); // new files
  const [uploadedFiles, setUploadedFiles] = useState([]); // uploaded
  const [removedFiles, setRemovedFiles] = useState([]); // removed uploadedFiles

  const { register, setValue, getValues } = useFormContext();
  const { errors, isDirty } = useFormState();
  const myErrors = errors[name];
  const myIsDirty = isDirty[name];
  const myRef = useRef(null);

  const fullName = `files.${name}`;

  const handleUpload = async (newFiles) => {
    const res = await sendAsFormData({ [name]: [...newFiles] }, action);
    const newFielsUploaded = res[name];
    // console.log(newFielsUploaded);
    if (Array.isArray(newFielsUploaded)) {
      setFiles((files) =>
        files.concat(
          [...newFielsUploaded].map((file) => ({
            key: file._id,
            file,
          }))
        )
      );
    }
  };

  const handleDelete = (key) => {
    setFiles((files) => files.filter((file) => file.key !== key));
  };

  const handleDeleteUploaded = (id) => {
    setUploadedFiles((files) => files.filter((file) => file._id !== id));
    setRemovedFiles((files) => [...files, id]);
  };

  // update new files in form data
  useEffect(() => {
    setValue(fullName, files);
  }, [files, fullName, setValue]);

  // update removed files in form data
  useEffect(() => {
    setValue(`filesRemoved.${name}`, removedFiles);
  }, [removedFiles, name, setValue]);

  // set initial files
  useEffect(() => {
    setUploadedFiles((files) => {
      const defaultValues = getValues(fullName);
      if (Array.isArray(defaultValues)) {
        return defaultValues;
      } else {
        return files;
      }
    });
  }, [fullName, getValues]);

  const defaultRegister = register(fullName);
  const customRegister = {
    ...defaultRegister,
    ref: (ref) => {
      myRef.current = ref; // input ref
      defaultRegister.ref(ref);
    },
    onChange: (e) => {
      defaultRegister.onChange(e);
      handleUpload(e.target.files);
    },
  };

  return useMemo(
    () => (
      <Card variant="outlined">
        <p className={styles['card__label']}>{label}</p>
        <Divider />
        <section className={styles['card__content']}>
          <input type="file" {...customRegister} multiple hidden {...rest} />
          <label>
            <Button
              component="div"
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={(e) => {
                e.preventDefault(); // prevent default click input on click
                myRef.current.click(); // click input on click or keyboard
              }}
              color={myErrors ? 'error' : 'primary'}
            >
              Upload
            </Button>
          </label>
          {myErrors && <p className={styles.error}>{myErrors.message}</p>}
          <ul className={styles.filelist}>
            <TransitionGroup>
              {uploadedFiles.map((item) => (
                <Collapse key={item._id}>
                  <Chip
                    className={styles.chip}
                    size="small"
                    onDelete={() => {
                      // handleDelete(item.id);
                      handleDeleteUploaded(item._id);
                    }}
                    deleteIcon={<DeleteIcon />}
                    label={
                      <a href={item.path} download>
                        <DownloadIcon />
                        <span>{item.name}</span>
                      </a>
                    }
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </ul>
          <ul className={styles.filelist}>
            <TransitionGroup>
              {files.map((item) => (
                <Collapse key={item.key}>
                  <Chip
                    className={styles.chip}
                    size="small"
                    onDelete={() => {
                      handleDelete(item.key);
                    }}
                    deleteIcon={<DeleteIcon />}
                    label={item.file.name}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </ul>
        </section>
      </Card>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myErrors, myIsDirty, files, uploadedFiles]
  );
};

Upload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  action: PropTypes.string.isRequired,
};

export default Upload;
