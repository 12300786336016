import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import SubNav from 'components/SubNav';

// UI component
const ManagementLayout = ({ pages }) => {
  return (
    <Container xs={{ margin: '2.4rem' }}>
      <Box component="h1" sx={{ padding: '2rem 0', maxWidth: 'md' }}>
        Management
      </Box>
      <SubNav pages={pages} />
      <Outlet />
    </Container>
  );
};

ManagementLayout.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManagementLayout;
