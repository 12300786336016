import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useSWRConfig } from 'swr';
import { updateTaskStatus } from 'services/apis';
import Authorized from 'components/Authorized';
import styles from './index.module.scss';

const StatusSelect = ({ status, handleChange }) => {
  const onChange = (e) => {
    const value = e.target.value;
    handleChange(value);
  };

  return (
    <Select
      label="Status"
      value={status}
      onChange={onChange}
      variant="standard"
      size="small"
      autoWidth={false}
      sx={{
        fontSize: 14,
        flexGrow: 1,
        '& .MuiSelect-select': { paddingBottom: 0 },
      }}
    >
      <MenuItem value={'open'}>Open</MenuItem>
      <MenuItem value={'submitted'}>Submitted</MenuItem>
      <MenuItem value={'approved'}>Approved</MenuItem>
    </Select>
  );
};

const StatusTooltip = ({ row, auth }) => {
  const { id, projectUid, status, logs } = row;
  const { mutate } = useSWRConfig(); // TODO: better dataflow and api config

  const renderTooltip =
    logs.length > 0 ? (
      logs.map((log, index) => (
        <p key={index}>
          {log.timestamp} | {log.status}
        </p>
      ))
    ) : (
      <p>no logs yet</p>
    );

  const handleChange = async (value) => {
    try {
      await updateTaskStatus(id, value);
      mutate('/api/tasks'); // TODO: better dataflow and api config
      mutate(`/api/projects/${projectUid}/tasks`); // TODO: better dataflow and api config
    } catch (err) {
      toast(err.message);
    }
  };

  return (
    <div className={styles['status-container']}>
      <Authorized
        authority="admin"
        userAuth={auth}
        noMatch={<span className={styles.status}>{status}</span>}
      >
        <StatusSelect status={status} handleChange={handleChange} />
        <Tooltip title={renderTooltip} arrow placement="right">
          <InfoOutlinedIcon color="info" />
        </Tooltip>
      </Authorized>
    </div>
  );
};

StatusSelect.propTypes = {
  status: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

StatusTooltip.propTypes = {
  row: PropTypes.object.isRequired,
  // taskId: PropTypes.string.isRequired,
  // status: PropTypes.string.isRequired,
  // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  auth: PropTypes.string,
};

export default StatusTooltip;
