import PropTypes from 'prop-types';

import NotAuthorized from './NotAuthorized';
import NotFound from './NotFound';

export const STATUS_NOT_FOUND = 404;
export const STATUS_NOT_AUTHORIZED = 403;

const Error = ({ status }) => {
  switch (status) {
    case 404: {
      return <NotFound />;
    }
    case 403: {
      return <NotAuthorized />;
    }
    default: {
      return <div>{status}</div>;
    }
  }
};

Error.propTypes = {
  status: PropTypes.number,
};

export default Error;
