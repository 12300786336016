import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import AsyncAutoComplete from 'components/AsyncAutoComplete';
import ProjectAccessTable from './ProjectAccessTable';

const ProjectAccess = () => {
  const [activeProject, setActiveProject] = useState(null);
  return (
    <div>
      <Box sx={{ display: 'flex', gap: '2rem', marginBottom: '2.4rem' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AsyncAutoComplete
            value={activeProject}
            setValue={setActiveProject}
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: '8rem' }}>
        <ProjectAccessTable uid={activeProject?.uid} />
      </Box>
    </div>
  );
};

export default ProjectAccess;
