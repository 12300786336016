import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircle';

const AddButton = ({ onClick, label }) => {
  return (
    <Button
      variant="contained"
      sx={{ fontSize: '1.6rem' }}
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default AddButton;
