import Grid from '@mui/material/Grid';

import { usePeople } from 'store/swr/usePeople';
import Loading from 'components/Loading';
import PeopleCard from 'components/PeopleCard';
import InfoCard from 'components/InfoCard';

const Projects = () => {
  const { data: { people = [] } = {}, loading, error } = usePeople();

  return (
    <Loading loading={loading} error={error}>
      <Grid container spacing={2} sx={{ padding: '2rem' }}>
        {people.map((item) => (
          <Grid key={item.id} item xs={12} sm={6} lg={4}>
            <PeopleCard item={item} />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} lg={4}>
          <InfoCard text={`Found ${people.length} People`} />
        </Grid>
      </Grid>
    </Loading>
  );
};

export default Projects;
