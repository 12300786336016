import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { dateFormatter } from 'utils/formatters';
import { useProjectByUid } from 'store/swr/useProjects';
import Loading from 'components/Loading';
import TableStyled from './TableStyled';

const createData = (label, value) => ({ label, value });

const getTotalCost = (fundingSource) => {
  const regex = /\$(.*?)(\s|$)/;
  let m;
  if ((m = regex.exec(fundingSource)) !== null) {
    return m[0];
  }
};

const DetailsTable = ({ uid }) => {
  const { data, loading, error } = useProjectByUid(uid);
  const project = data?.project;
  const rows = project
    ? [
        createData('Title', project.title),
        createData('Principal Investigator', project.PI.name),
        createData(
          'Participating Universities',
          project.universities.map((item) => item.name).join('; ')
        ),
        createData(
          'Funding Source',
          project.fundingSource.text
          // Object.entries(project.fundingSource.t)
          //   .map((item) => `${item[0]}: ${item[1]}`)
          //   .join('; ')
        ),
        createData(
          'Total Project Cost',
          project.totalCost
          // getTotalCost(project.fundingSource.text)
        ),
        createData('USDOT Award #', project.awardNumber),
        createData('Start Date', dateFormatter(project.startDate)),
        createData('End Date', dateFormatter(project.endDate)),
        createData('Brief Description', project.details.description),
        createData(
          'Implementation of Research Outcomes',
          project.details.implementation
        ),
        createData(
          'Impacts/Benefits of Implementation',
          project.details.impacts
        ),
      ]
    : [];

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Loading loading={loading} error={error}>
      <TableStyled label="Project Details">
        <TableBody>
          {rows.map((row) =>
            mobile ? (
              <Fragment key={row.label}>
                <TableRow>
                  <TableCell align="left">
                    <b>{row.label}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    {row.value.split('\n').map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </TableCell>
                </TableRow>
              </Fragment>
            ) : (
              <TableRow key={row.label}>
                <TableCell align="left">
                  <b>{row.label}</b>
                </TableCell>
                <TableCell align="left">
                  {row.value.split('\n').map((line, index) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableStyled>
    </Loading>
  );
};

DetailsTable.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default DetailsTable;
