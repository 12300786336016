import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GridActionsCellItem, DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// import useMutationObserver from 'utils/useMutationObserver';
import { percentFormatter, dateFormatter } from 'utils/formatters';
import StatusTooltip from './StatusTooltip';
import FileLinks from './FileLinks';
import Links from './Links';

/// Fields ///
export const getTaskFields = (category, auth, showProject = false) => {
  // general
  let fields = [];

  // related documents
  if (category === 'documents') {
    return ['item', 'uploads', 'links', 'actions'];
  }

  // show project name
  if (showProject) {
    fields.push('project');
  }
  if (category === 'all') {
    fields.push('category');
  }

  fields = fields.concat(['item', 'status', 'deadline', 'logs']);

  // special fields
  switch (category) {
    case 'quarterly-report': {
      fields.push('percentQuarter', 'percentProject');
      break;
    }
    case 'datasets': {
      fields.push('links');
      break;
    }
    default: {
      break;
    }
  }
  // authed fields
  if (!auth) {
    return fields;
  }
  fields.push('uploads', 'actions');
  return fields;
};

/// Task Columns ///
const makeTaskColumnsAll = (auth, editCallback, deleteCallback) => ({
  project: {
    field: 'project',
    headerName: 'Project',
    width: 240,
    valueFormatter: (params) => {
      // console.log(params);
      return params.value.title;
    },
    valueGetter: (params) => params.value.title,
  },
  category: {
    field: 'category',
    headerName: 'Category',
    width: 140,
  },
  item: {
    field: 'item',
    headerName: 'Item',
    width: 240,
  },
  status: {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
      return <StatusTooltip row={params.row} auth={auth} />;
    },
    valueGetter: (params) => params.row.status,
    type: 'string',
  },
  deadline: {
    field: 'deadline',
    headerName: 'Deadline',
    type: 'date',
    width: 110,
    align: 'center',
    valueFormatter: (params) => dateFormatter(params.value),
  },
  percentQuarter: {
    field: 'percentQuarter',
    headerName: 'Percent Complete This Quarter',
    width: 110,
    align: 'center',
    valueFormatter: (params) => percentFormatter(params.value),
  },
  percentProject: {
    field: 'percentProject',
    headerName: 'Percent Complete Total Project',
    width: 110,
    align: 'center',
    valueFormatter: (params) => percentFormatter(params.value),
  },
  uploads: {
    field: 'uploads',
    headerName: 'Uploaded Documents',
    width: 320,
    sortable: false,
    renderCell: (params) => <FileLinks row={params.row} />,
  },
  links: {
    field: 'links',
    headerName: 'Links',
    width: 200,
    sortable: false,
    renderCell: (params) => <Links row={params.row} />,
  },
  actions: {
    field: 'actions',
    headerName: deleteCallback ? 'Edit' : 'Update',
    type: 'actions',
    getActions: (params) => {
      const actions = [
        <GridActionsCellItem
          key="update"
          label="Update"
          icon={<EditIcon />}
          onClick={() => {
            // a quick and dirty fix for this focus bug //
            // https://github.com/mui-org/material-ui-x/issues/3158
            document.body.click();
            // bug fix end //
            editCallback(params);
          }}
        />,
      ];
      if (!deleteCallback) {
        return actions;
      }
      actions.push(
        <GridActionsCellItem
          key="delete"
          label="Delete"
          icon={<DeleteIcon />}
          onClick={() => {
            // a quick and dirty fix for this focus bug //
            // https://github.com/mui-org/material-ui-x/issues/3158
            document.body.click();
            // bug fix end //
            deleteCallback(params);
          }}
        />
      );
      return actions;
    },
  },
});

export const getTaskColumns = (
  fields,
  auth,
  editCallback = null,
  deleteCallback = null
) => {
  const taskColumnsAll = makeTaskColumnsAll(auth, editCallback, deleteCallback);
  return fields
    .map((field) => taskColumnsAll[field])
    .filter((col) => col !== undefined);
};

/// Task Rows ///
const normalizeTask = (task) => {
  return {
    ...task,
    id: task._id,
    projectUid: task.project.uid,
    percentQuarter: task.progress?.percentQuarter,
    percentProject: task.progress?.percentProject,
  };
};

export const getTaskRows = (tasks) => {
  return tasks.map((task) => normalizeTask(task));
};

const DataGridStyled = ({ rows, columns }) => {
  const [targetEl, setTargetEl] = useState(null);

  // callback ref to root
  const onRefChange = useCallback((node) => {
    setTargetEl(node);
  }, []);

  // This useEffect make sure the scroller height is not overwritten by Mui
  // ---
  // mui will set height of the scroller to a fixed value with inline style
  // (that value is calculated from the fixed row hieght
  // but we changed the default row height to fit the content
  // see styles/_base.scss)
  // reset the scroller height to fit-content on each render
  useEffect(() => {
    if (!targetEl) {
      return;
    }
    const scroll = targetEl.querySelector(
      '.MuiDataGrid-virtualScrollerContent'
    );
    if (!scroll) {
      return;
    }
    setTimeout(() => {
      // this hack make our update happen after mui sets the value
      // any better way?
      scroll.style.height = 'fit-content';
    });
  });

  return (
    <div style={{ display: 'flex', height: '100%', marginBottom: '8rem' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[10]}
          headerHeight={120}
          rowHeight={80}
          autoHeight
          ref={onRefChange}
          disableColumnMenu
          density="compact"
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1.2,
            },
            '& .MuiDataGrid-virtualScrollerRenderZone': {
              position: 'relative', // default absolute
            },
            '& .virtualScrollerContent': {
              height: 'fit-content', // display all reder-zone, no scroll
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '6.4rem', // min-height when no data
            },
          }}
        />
      </div>
    </div>
  );
};

DataGridStyled.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DataGridStyled;
