import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  useProjectByUid,
  useResearchAreas,
  useUniversities,
} from 'store/swr/useProjects';
import { usePeople } from 'store/swr/usePeople';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Form,
  Input,
  DatePicker,
  ChipSelect,
  AutoComplete,
  SubmitButton,
} from 'components/Form';
import GridCard from 'components/Form/GridCard';

import Loading from 'components/Loading';
import { createProject, updateProject } from 'services/apis';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    title: yup.string().required(),
    PI: yup
      .object()
      .typeError('Please enter the Principal Investigator')
      .required('Please enter the Principal Investigator'),
    coPIs: yup.array().of(yup.object()),
    universities: yup.array().min(1),
    fundingSource: yup
      .object()
      .shape({
        text: yup.string().required(),
      })
      .required(),
    totalCost: yup.string().required(),
    awardNumber: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    researchAreas: yup.array(),
    details: yup
      .object()
      .shape({
        description: yup.string().required(),
        implementation: yup.string().required(),
        impacts: yup.string().required(),
      })
      .required(),
    uid: yup.string().required(),
  })
  .required();

const ProjectForm = ({ uid, afterSubmit, setDirty }) => {
  const {
    data: { project } = {},
    loading,
    error,
    mutate,
  } = useProjectByUid(uid);
  const {
    data: { universities = [] } = {},
    uLoading,
    uError,
  } = useUniversities();
  const {
    data: { researchAreas = [] } = {},
    rLoading,
    rError,
  } = useResearchAreas();
  const { data: { people = [] } = {}, pLoading, pError } = usePeople();

  // console.log(project);
  // console.log('uid', uid);

  const onSubmit = async (project) => {
    try {
      let res;
      if (uid) {
        res = await updateProject(uid, project);
      } else {
        res = await createProject(project.uid, project);
      }
      mutate();
      afterSubmit(res);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading
      loading={loading || uLoading || rLoading || pLoading}
      error={error || uError || rError || pError}
    >
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: project,
          resolver: yupResolver(schema),
        }}
        setDirty={setDirty}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <GridCard label="Project Details">
            <Grid item xs={12}>
              <Input name="title" label="Title" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutoComplete name="PI" label="PI" options={people} required />
            </Grid>
            <Grid item xs={12} md={10}>
              <ChipSelect name="coPIs" label="Co-PIs" options={people} />
            </Grid>
            <Grid item xs={12} md={10}>
              <ChipSelect
                name="universities"
                label="Participating Universities"
                options={universities}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="fundingSource.text"
                label="Funding Source"
                required
                multiline
                minRows={2}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Input name="totalCost" label="Total Cost" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input name="awardNumber" label="USDOT Award #" required />
            </Grid>
            <Box width="100%" />
            <Grid item xs={12} md={6}>
              <DatePicker name="startDate" label="Start Date" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker name="endDate" label="End Date" required />
            </Grid>
            <Grid item xs={12} md={10}>
              <ChipSelect
                name="researchAreas"
                label="Research Areas"
                options={researchAreas}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Input
                name="details.description"
                label="Brief Description"
                required
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Input
                name="details.implementation"
                label="Implementation of Research Outcomes"
                required
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <Input
                name="details.impacts"
                label="Impacts/Benefits of Implementation"
                required
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input name="uid" label="Unique ID" required />
            </Grid>
          </GridCard>

          <GridCard label="Data Management Plan">
            <Grid item xs={12}>
              <Input
                name="dataManagementPlan.description"
                label="Data Description"
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="dataManagementPlan.format"
                label="Data Format"
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="dataManagementPlan.accessPolicy"
                label="Data Policy for Access"
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="dataManagementPlan.reusePolicy"
                label="Data Policy for Reuse"
                multiline
                minRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="dataManagementPlan.plan"
                label="Data Plan"
                multiline
                minRows={4}
              />
            </Grid>
          </GridCard>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

ProjectForm.propTypes = {
  uid: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
  setDirty: PropTypes.func,
};

export default ProjectForm;
