// import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ChipSelect = ({ label, name, options, ...rest }) => {
  const { control } = useFormContext();
  // const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // console.log(value);
        // console.log(value, fieldState);
        return (
          <Autocomplete
            multiple
            value={value || []}
            onChange={(_, data) => onChange(data)}
            // inputValue={inputValue}
            // onInputChange={(_, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            options={options}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.name}
            label={label}
            size="small"
            margin="dense"
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!error}
                helperText={error?.message ?? ''}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  required: rest?.required && value?.length === 0,
                }}
                {...rest}
              />
            )}
          />
        );
      }}
      onChange={([, data]) => data}
      name={name}
      control={control}
    />
  );
};

ChipSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default ChipSelect;
