import PropTypes from 'prop-types';
import { matchProjectAuth } from 'utils/matchAuth';

const Authorized = ({ authority, userAuth, children, noMatch }) => {
  return matchProjectAuth(authority, userAuth) ? children : noMatch;
};

Authorized.propTypes = {
  authority: PropTypes.string.isRequired,
  userAuth: PropTypes.string, // can be null
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  noMatch: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Authorized;
