import { useEffect } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';

import ProjectForm from 'forms/ProjectForm';
import { useProjects } from 'store/swr/useProjects';

const Overview = () => {
  const { uid } = useParams();
  const { setActiveProject, setIsFormtDirty } = useOutletContext();
  const { mutate } = useProjects();
  const navigate = useNavigate();

  useEffect(() => {
    // null for new project, undefined for nothing selected
    setActiveProject(uid === 'new' ? null : { uid });
    return () => {
      if (uid === 'new') {
        setActiveProject(undefined);
      }
    };
  }, [uid, setActiveProject]);

  const afterSubmitProject = (project) => {
    mutate();
    setActiveProject(project);
    setIsFormtDirty(false);
    navigate(`../../${project.uid}/overview`);
  };

  return (
    <ProjectForm
      sx={{ margin: '0 auto' }}
      uid={uid === 'new' ? null : uid}
      afterSubmit={afterSubmitProject}
      setDirty={setIsFormtDirty}
    />
  );
};

export default Overview;
