import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

const GridCard = ({ label, children }) => {
  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        {label && (
          <>
            <Box sx={{ padding: '.8rem 2.4rem', fontSize: '1.8rem' }}>
              {label}
            </Box>
            <Divider sx={{ marginBottom: '1.2rem' }} />
          </>
        )}
        <CardContent sx={{ padding: '1.6rem 2.4rem' }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {children}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

GridCard.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default GridCard;
