import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GoogleLogin } from 'react-google-login';
import toast, { Toaster } from 'react-hot-toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import config from 'config';
import { authGoogle, authSelector } from 'store/slices/AuthSlice';

import SignInForm from 'forms/SignInForm';

import styles from './index.module.scss';

export default function SignInDialog() {
  const { error } = useSelector(authSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoogleSucces = async (response) => {
    dispatch(authGoogle(response.tokenId));
  };

  const handleGoogleFailure = (response) => {
    toast.error(response.error);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div>
      <div className={styles.btn} role="button" onClick={handleClickOpen}>
        Sign In
      </div>

      <Dialog className={styles.dialog} open={open} onClose={handleClose}>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <GoogleLogin
            clientId={config.googleClientId}
            buttonText="Sign In with Google"
            onSuccess={handleGoogleSucces}
            onFailure={handleGoogleFailure}
            cookiePolicy={'single_host_origin'}
          />
          <div className="g_id_signin" data-type="standard"></div>

          <SignInForm />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <Toaster />
      </Dialog>
    </div>
  );
}
