import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import styles from './index.module.scss';

const HeaderMenu = ({ pages }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // hide menu when changed to large screen
  useEffect(() => {
    if (!mobile) {
      handleCloseNavMenu();
    }
  }, [mobile]);

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon className={styles['menu-icon']} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        className={styles.menu}
      >
        {pages.map((page) => (
          <MenuItem key={page.link}>
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : null)}
              to={page.link}
            >
              {page.label}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

HeaderMenu.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object),
};

export default HeaderMenu;
