import PropTypes from 'prop-types';

import styles from './index.module.scss';

const ResearchAreaTag = ({ item }) => {
  return (
    <span
      className={styles.tag}
      style={{
        border: `thin solid var(--color-area-${item._id})`,
        borderLeftWidth: '10px',
      }}
    >
      {item.name}
    </span>
  );
};

ResearchAreaTag.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ResearchAreaTag;
