import { Routes, Route } from 'react-router-dom';

import ManageProjectLayout from './ManageProjectLayout';
import ProjectSelect from './ProjectSelect';
import Overview from './Overview';
import Tasks from './Tasks';
import Error, { STATUS_NOT_FOUND } from 'pages/Error';

const pages = [
  {
    label: 'Select a project to edit',
    link: '',
  },
  {
    label: 'Edit project information',
    link: ':uid/overview',
    allowNew: true, // allow uid to be new for new items
  },
  {
    label: 'Edit project tasks',
    link: ':uid/tasks',
  },
];

const Project = () => {
  return (
    <Routes>
      <Route path="/*" element={<ManageProjectLayout pages={pages} />}>
        <Route index exact element={<ProjectSelect />} />
        <Route path=":uid/overview" element={<Overview />} />
        <Route path=":uid/tasks" element={<Tasks />} />
        <Route path="*" element={<Error status={STATUS_NOT_FOUND} />} />
      </Route>
    </Routes>
  );
};

export default Project;
