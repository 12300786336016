// import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AdapterDateFns from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// import MobileDatePicker from '@mui/lab/MobileDatePicker';

const MyDatePicker = ({ name, label, ...rest }) => {
  const { control } = useFormContext();

  // const [value, setValue] = useState(new Date());
  // const onChange = (newValue) => {
  //   console.log(newValue);
  //   setValue(newValue);
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <DesktopDatePicker
            label={label}
            inputFormat="MM/dd/yyyy"
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                size="small"
                margin="dense"
                fullWidth
                {...params}
                {...rest}
              />
            )}
          />
        )}
        name={name}
        control={control}
      />
    </LocalizationProvider>
  );
};

MyDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default MyDatePicker;
