// role if for global
export const AUTH_ADMIN = 'admin';
export const AUTH_EDITOR = 'editor';

// for global
export function matchGlobalAuth(authRequire, authUser) {
  switch (authRequire) {
    case AUTH_ADMIN: {
      return authUser === AUTH_ADMIN;
    }
    default: {
      return true;
    }
  }
}

// for each project
export function matchProjectAuth(authRequire, authUser) {
  switch (authRequire) {
    case AUTH_ADMIN: {
      return authUser === authRequire;
    }
    case AUTH_EDITOR: {
      return authUser === AUTH_ADMIN || authUser === AUTH_EDITOR;
    }
    default: {
      return true;
    }
  }
}
