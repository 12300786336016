import centerLogo from 'assets/images/c2smart.png';
import styles from './index.module.scss';

const Animated = () => {
  return (
    <div className={styles.container}>
      <div className={styles['logo-container']}>
        <img className={styles.logo} src={centerLogo} alt="C2SMART Center" />
      </div>
      <div className={styles.animated}>
        {[...'Common Innovation Platform'].map((c, index) => (
          <span
            key={index}
            className={styles[`color--${index % 6}`]}
            style={{ animationDelay: `${index * 0.03}s` }}
          >
            {c === ' ' ? <>&nbsp;</> : c}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Animated;
