import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { hash } from 'utils/encrypt';
import { authPassword } from 'store/slices/AuthSlice';
import { Form, Input, Password, SubmitButton } from 'components/Form';

const SignInForm = () => {
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const hashed = { ...data, password: hash(data.password) };
    dispatch(authPassword(hashed));
  };

  return (
    <Box>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
        }}
      >
        <Box sx={{ margin: '1.6rem 0' }}>
          <Input
            name="email"
            label="Email"
            type="email"
            autoComplete="username"
          />
          <Password name="password" autoComplete="current-password" />
        </Box>
        <SubmitButton />
      </Form>
    </Box>
  );
};

export default SignInForm;
