import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';

const SubNav = ({ pages, level = 0 }) => {
  return (
    <nav className={`${styles.nav} ${styles[`nav--level${level}`]}`}>
      {pages.map((page) => (
        <NavLink
          key={page.link}
          to={page.link}
          className={({ isActive }) =>
            `${styles.nav__item} ${styles[`nav__item--level${level}`]} ${
              isActive ? 'active' : ''
            }`
          }
        >
          {page.label}
        </NavLink>
      ))}
    </nav>
  );
};

SubNav.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  level: PropTypes.number,
};

export default SubNav;
