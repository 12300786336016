import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import ResearchAreaTag from '../ResearchAreaTag';

const ProjectCard = ({ item }) => {
  return (
    <section className={styles.card}>
      <h3 className={styles.card__title}>
        <Link to={item.uid}>{item.title}</Link>
      </h3>
      <section className={styles.card__line}>
        {item.researchAreas.map((area) => (
          <ResearchAreaTag key={area._id} item={area} />
        ))}
      </section>
      <p className={styles.card__line}>
        Principal Investigator: {item.PI.name}
      </p>
    </section>
  );
};

ProjectCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ProjectCard;
