import { Fragment } from 'react';
import PropTypes from 'prop-types';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useProjectByUid } from 'store/swr/useProjects';
import Loading from 'components/Loading';
import TableStyled from './TableStyled';

const createData = (label, value) => ({ label, value });

const DmpTable = ({ uid }) => {
  const { data, loading, error } = useProjectByUid(uid);
  const dmp = data?.project?.dataManagementPlan;

  const rows = [
    createData('Data Description', dmp?.description),
    createData('Data Format and Metadata Standards', dmp?.format),
    createData('Policies for Access and Sharing', dmp?.accessPolicy),
    createData(
      'Policies for Reuse, Redistribution, Derivatives',
      dmp?.reusePolicy
    ),
    createData('Plans for Archiving and Preservation', dmp?.plan),
  ];

  return (
    <Loading loading={loading} error={error}>
      <TableStyled label="Data Management Plan">
        <TableBody>
          {rows.map((row) => (
            <Fragment key={row.label}>
              <TableRow>
                <TableCell align="left">
                  <b>{row.label}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {row.value.split('\n').map((line, index) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </TableStyled>
    </Loading>
  );
};

DmpTable.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default DmpTable;
