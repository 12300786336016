import Box from '@mui/material/Box';
import BackgroundImg from 'assets/images/background.jpg';
import AnimatedTitle from 'components/AnimatedTitle';

const BackgroundHead = {
  flexGrow: 1,
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
};

const Banner = () => {
  return (
    <Box sx={BackgroundHead}>
      <AnimatedTitle />
    </Box>
  );
};

export default Banner;
