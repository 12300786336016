import { useFormState } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';

const SubmitButton = () => {
  const { isValid, isSubmitting, errors } = useFormState();
  // console.log(isValid, errors);
  // console.log(isSubmitting);
  return (
    <LoadingButton
      type="submit"
      variant="contained"
      color="primary"
      disabled={!isValid}
      loading={isSubmitting}
      fullWidth
    >
      Submit
    </LoadingButton>
  );
};

export default SubmitButton;
