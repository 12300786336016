import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';

import { useDocument } from 'store/swr/useTasks';
import { createDocument, updateDocument } from 'services/apis';

import { Form, Input, Links, Upload, SubmitButton } from 'components/Form';
import GridCard from 'components/Form/GridCard';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    item: yup.string().required().required('Please enter a name'),
  })
  .required();

const DocumentsForm = ({ projectUid, documentId, afterSubmit }) => {
  const {
    data: { document = null } = {},
    loading,
    error,
    mutate,
  } = useDocument(documentId);

  const onSubmit = async (data) => {
    try {
      if (documentId) {
        await updateDocument(documentId, data);
        mutate();
      } else {
        await createDocument(projectUid, data);
      }
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: document,
          resolver: yupResolver(schema),
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <GridCard label="Item Name">
            <Grid item xs={12} md={8} xl={6}>
              <Input name="item" label="Item Name" multiline required />
            </Grid>
          </GridCard>
          <GridCard label="Link to External Files">
            <Grid item xs={12}>
              <Links name="dataLink" label="Link to data" />
            </Grid>
          </GridCard>

          <Grid item xs={12}>
            <Upload
              name="files"
              label="Upload Documents"
              action={
                documentId
                  ? `/api/documents/${documentId}/upload`
                  : `/api/projects/${projectUid}/documents/upload`
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

DocumentsForm.propTypes = {
  projectUid: PropTypes.string.isRequired,
  documentId: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
};

export default DocumentsForm;
