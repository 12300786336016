import * as React from 'react';
import { PropTypes } from 'prop-types';

import { Outlet, NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';

import styles from './index.module.scss';

export default function HorizontalNonLinearStepper({ pages, context }) {
  const { activeProject } = context;
  // console.log('activeProject', activeProject);
  const getLinkStatus = (page) => {
    const link = page.link;
    if (link.length === 0) {
      // index page always valid and no replacement needed
      return { link, enabled: true };
    }

    // unset
    if (activeProject === undefined) {
      return { link: '', enabled: false };
    }

    // project is null (instead of undefined) for a new project yet to be submitted
    // only allowed pages (overview page) can have null project
    if (activeProject === null && !page.allowNew) {
      return { link: '', enabled: false };
    }
    // replace the :uid placeholder to the real value
    const uid = activeProject?.uid || 'new';
    const realLink = link.replace(':uid', uid);
    return { link: realLink, enabled: true };
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className={styles.navlink__wrapper}>
        {pages.map((page, index) => {
          const { link, enabled } = getLinkStatus(page);
          return (
            <React.Fragment key={page.link}>
              {index > 0 && <div className={styles.navlink__line}></div>}
              <NavLink
                end
                to={link}
                className={({ isActive }) =>
                  `${styles.navlink} ${isActive && enabled ? 'active' : ''} ${
                    enabled ? '' : 'disabled'
                  }`
                }
              >
                <span className={styles.navlink__label}>{page.label}</span>
                <div className={styles.navlink__icon} />
              </NavLink>
            </React.Fragment>
          );
        })}
      </div>
      <Box
        sx={{
          margin: '0 auto',
          padding: '2.4rem',
          width: '100%',
          maxWidth: '1080rem',
        }}
      >
        <Outlet context={context} />
      </Box>
    </Box>
  );
}

// HorizontalNonLinearStepper.propTypes = {
//   steps: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       component: PropTypes.oneOfType([
//         PropTypes.arrayOf(PropTypes.node),
//         PropTypes.node,
//         PropTypes.func,
//       ]).isRequired,
//     })
//   ).isRequired,
//   activeStep: PropTypes.number.isRequired,
//   validateSetStep: PropTypes.func.isRequired,
// };

HorizontalNonLinearStepper.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  context: PropTypes.object,
};
