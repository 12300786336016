import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { dateFormatter } from 'utils/formatters';
import { sendAsJSON } from 'utils/formdata';

export default function AlertDialog({ project, afterSubmit }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const url = `/api/projects/${project.uid}`;
    const err = await sendAsJSON({ project }, url, 'DELETE');
    if (!err) {
      handleClose();
      afterSubmit();
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="error"
        sx={{ width: '12rem', fontSize: '1.6rem', height: '100%' }}
        startIcon={<DeleteForeverIcon />}
        disabled={!project}
        onClick={handleClickOpen}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="project-deletion-dialog-title"
        aria-describedby="project-deletio-dialog-description"
      >
        {project && (
          <>
            <DialogTitle>
              Delete this project and all related tasks?
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                <b>{project.title}</b>
              </DialogContentText>
              <hr />
              <DialogContentText>
                Principal Investigator: {project.PI?.name}
              </DialogContentText>
              <hr />
              <DialogContentText>
                {`Start Date / End Date: ${dateFormatter(
                  project.startDate
                )} / ${dateFormatter(project.endDate)}`}
              </DialogContentText>
              <hr />
              <DialogContentText>
                {project.details?.description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Cancel
              </Button>
              <Button color="error" onClick={handleDelete}>
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  project: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};
