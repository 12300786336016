import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';

import { useUser } from 'store/swr/usePeople';
import { hash } from 'utils/encrypt';
import { createUser, updateUser } from 'services/apis';

import {
  Form,
  Input,
  Password,
  AutoComplete,
  SubmitButton,
} from 'components/Form';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().typeError('Please select a role').required(),
    password: yup.string(),
  })
  .required();

const UserForm = ({ userId, afterSubmit }) => {
  const { data: { user = {} } = {}, loading, error } = useUser(userId);

  const onSubmit = async (data) => {
    try {
      if (userId) {
        await updateUser(userId, data);
      } else {
        await createUser(data);
      }
      afterSubmit();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Loading loading={loading} error={error}>
      <Form
        onSubmit={onSubmit}
        useFormParam={{
          mode: 'onChange',
          defaultValues: user,
          resolver: yupResolver(schema),
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Input name="name" label="Name" required />
          </Grid>
          <Grid item xs={12}>
            <Input name="email" label="Email" required />
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              name="role"
              label="Role"
              options={['user', 'admin']}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Password
              name="password"
              label={user.passwordExists ? 'Update password' : 'Password'}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton />
          </Grid>
        </Grid>
      </Form>
    </Loading>
  );
};

UserForm.propTypes = {
  userId: PropTypes.string,
  afterSubmit: PropTypes.func.isRequired,
};

export default UserForm;
